export const ROLE = {
  SUPER_ADMIN: 'super_admin',
  MANAGING_ADMIN: 'managing_admin', // PHO_GIAM_DOC_DIEU_HANH
  FINANCE_ADMIN: 'finance_admin', // PHO_GIAM_DOC_TAI_CHINH
  // manage
  CUSTOMER_SERVICE_MANAGER: 'customer_service_manager', // TRUONG_PHONG_CSKH
  CUSTOMER_SERVICE_ACCOUNT: 'customer_service_account', // CHAM_SOC_KHACH_HANG
  ADMINISTRATIVE_ACCOUNT: 'administrative_account', // NHAN_VIEN_HANH_CHINH
  ADMINISTRATIVE_MANAGER: 'administrative_manager', // QUAN_LY_PHONG_HANH_CHINH
  ACCOUNTANT_ACCOUNT: 'accountant_account', // NHAN_VIEN_KE_TOAN
  CHIEF_ACCOUNTANT_ACCOUNT: 'chief_accountant_account', // TRUONG PHONG KE_TOAN
  WAREHOUSE_ACCOUNT: 'warehouse_account', // NHAN_VIEN_KHO
  ADDITIONAL_PROFILE_ACCOUNT: 'additional_profile_account', // Tài khoản bổ sung hồ sơ
  // finance
  BUSINESS_MANAGER: 'business_manager', // TRUONG_PHONG_KINH_DOANH
  SHIPPER_ACCOUNT: 'shipper_account', // NHAN_VIEN_GIAO_HANG
  SELLER_ACCOUNT: 'seller_account', // NHAN_VIEN_BAN_HANG
  EXTERNAL_ACCOUNT: 'external_account',
  AGENT_EXTERNAL_ACCOUNT: 'agent_external_account', // DAI_LY
  BUSINESS_CUSTOMER_EXTERNAL_ACCOUNT: 'business_customer_external_account', // KHACH_HANG_DOANH_NGHIEP
  COLLABORATOR_EXTERNAL_ACCOUNT: 'collaborator_external_account', // CONG_TAC_VIEN
  RETAIL_CUSTOMER_EXTERNAL_ACCOUNT: 'retail_customer_external_account', // KHACH_HANG_BAN_LE
  // technical
  TECHNICAL_MANAGER: 'technical_manager', // TRUONG_PHONG_KY_THUAT
  TECHNICAL_ACCOUNT: 'technical_account', // NHAN_VIEN_KY_THUAT
  // marketting
  MARKETTING_MANAGER: 'marketting_manager', // TRUONG_PHONG_MARKETTING
  MARKETTING_ACCOUNT: 'marketting_account', // NHAN_VIEN_MARKETTING

  // partner
  PARTNER_REPRESENTATIVE: 'partner_representative',
  PARTNER_SELLER: 'partner_seller',

}

export const ROLE_ACCOUNT = [
  ROLE.CUSTOMER_SERVICE_ACCOUNT,
  ROLE.ADMINISTRATIVE_ACCOUNT,
  ROLE.ACCOUNTANT_ACCOUNT,
  ROLE.WAREHOUSE_ACCOUNT,
  ROLE.SHIPPER_ACCOUNT,
  ROLE.SELLER_ACCOUNT,
  ROLE.TECHNICAL_ACCOUNT,
  ROLE.MARKETTING_ACCOUNT,
]

export const ROLE_INTERNAL_DIAGRAM = {
  role: ROLE.SUPER_ADMIN,
  level: 0,
  children: [
    {
      role: ROLE.MANAGING_ADMIN,
      level: 1,
      children: [
        {
          role: ROLE.CUSTOMER_SERVICE_MANAGER,
          level: 2,
          children: [
            {
              role: ROLE.CUSTOMER_SERVICE_ACCOUNT,
              level: 3,
              children: null,
            },
          ],
        },
        {
          role: ROLE.ADMINISTRATIVE_MANAGER,
          level: 2,
          children: [
            {
              role: ROLE.ADMINISTRATIVE_ACCOUNT,
              level: 3,
              children: null,
            },
            {
              role: ROLE.ACCOUNTANT_ACCOUNT,
              level: 3,
              children: null,
            },
            {
              role: ROLE.WAREHOUSE_ACCOUNT,
              level: 3,
              children: null,
            },
            {
              role: ROLE.ADDITIONAL_PROFILE_ACCOUNT,
              level: 3,
              children: null,
            },
          ],
        },
      ],
    },
    {
      role: ROLE.FINANCE_ADMIN,
      level: 1,
      children: [
        {
          role: ROLE.BUSINESS_MANAGER,
          level: 2,
          children: [
            {
              role: ROLE.SHIPPER_ACCOUNT,
              level: 3,
              children: null,
            },
            {
              role: ROLE.SELLER_ACCOUNT,
              level: 3,
              children: null,
            },
            {
              role: ROLE.EXTERNAL_ACCOUNT,
              level: 3,
              children: null,
            },
            {
              role: ROLE.PARTNER_REPRESENTATIVE,
              level: 3,
              children: null,
            },
          ],
        },
        {
          role: ROLE.TECHNICAL_MANAGER,
          level: 2,
          children: [
            {
              role: ROLE.TECHNICAL_ACCOUNT,
              level: 3,
              children: null,
            },
          ],
        },
        {
          role: ROLE.MARKETTING_MANAGER,
          level: 2,
          children: [
            {
              role: ROLE.MARKETTING_ACCOUNT,
              level: 3,
              children: null,
            },
          ],
        },
      ],
    },
  ],
}

export const externalAccount = [
  ROLE.EXTERNAL_ACCOUNT,
  ROLE.AGENT_EXTERNAL_ACCOUNT,
  ROLE.BUSINESS_CUSTOMER_EXTERNAL_ACCOUNT,
  ROLE.COLLABORATOR_EXTERNAL_ACCOUNT,
  ROLE.RETAIL_CUSTOMER_EXTERNAL_ACCOUNT,
  // ROLE.PARTNER_REPRESENTATIVE,
  // ROLE.PARTNER_SELLER,
]

export const PERMISSION = {
  // MANAGE BONUS
  MANGAGE_BONUS: 'MANGAGE_BONUS', // QUAN LY DIEM THUONG
  VIEW_REQUEST_CHANGE_POINT: 'VIEW_REQUEST_CHANGE_POINT', // XEM_YEU_CAU_DOI_DIEM
  APPROVE_BONUS: 'APPROVE_BONUS', // PHE DUYET DIEM THUONG

  // SIM
  CHANGE_STATUS_TO_SOLD: 'CHANGE_STATUS_TO_SOLD', // CHUYEN_TRANG_THAI_SANG_DA_BAN

  // SIM_GOI
  UPLOAD_LIST_SIM_GOI: 'UPLOAD_LIST_SIM_GOI', // UPLOAD_DANH_SACH_SIM
  APPROVE_LIST_UPLOAD_SIM_GOI: 'APPROVE_LIST_UPLOAD_SIM_GOI', // QUAN LY DANH SACH UPLOAD CAN PHE DUYET
  UPDATE_LIST_UPLOAD_SIM_GOI: 'UPDATE_LIST_UPLOAD_SIM_GOI', // PHE_DUYET_DANH_SACH_UPLOAD (TU CHOI HOAC DUYET)
  MANAGE_SIM_WAREHOUSE_SIM_GOI: 'MANAGE_SIM_WAREHOUSE_SIM_GOI', // QUAN_LY_KHO_SIM (THẤY NAVIGATOR QUAN LY KHO SIM GÓI)
  MANAGE_SIM_ACTIVATION_SIM_GOI: 'MANAGE_SIM_ACTIVATION_SIM_GOI', // QUAN_LY_KICH_HOAT_SIM
  EDIT_DETAIL_SIM_WAREHOUSE_SIM_GOI: 'EDIT_DETAIL_SIM_WAREHOUSE_SIM_GOI', // SUA_THONG_TIN_GOI_CUOC
  VISIBLE_SIM_GOI: 'VISIBLE_SIM_GOI',
  FILTER_SIM_GOI: 'FILTER_SIM_GOI', // BO_LOC_DANH_SACH_SIM_GOI
  FILTER_BY_SELLER_SIM_PACKAGED: 'FILTER_BY_SELLER_SIM_PACKAGED', // LOC DANH SACH SIM GOI THEO SELLER
  // // SIM_GOI detail
  RESTORE_LIST_SIM_GOI: 'RESTORE_LIST_SIM_GOI', // KHOI_PHUC_SIM_GOI
  REMOVE_NUMBER_SIM_GOI: 'REMOVE_NUMBER_SIM_GOI', // GO_SO_SIM_GOI
  EDIT_BUTTON_DETAIL_SIM_GOI: 'EDIT_BUTTON_DETAIL_SIM_GOI', // BUTTON_SUA_DETAIL_SIM_GOI
  REMOVE_BUTTON_DETAIL_SIM_GOI: 'REMOVE_BUTTON_DETAIL_SIM_GOI', // BUTTON_GO_DETAIL_SIM_GOI
  RESTORE_BUTTON_DETAIL_SIM_GOI: 'RESTORE_BUTTON_DETAIL_SIM_GOI', // BUTTON_KHOI_PHUC_DETAIL_SIM_GOI
  VIEW_COMMISSION_RATE: 'VIEW_COMMISSION_RATE', // XEM_TI_LE_HOA_HONG_SIM_GOI
  REQUEST_ACTIVE_SIM_GOI: 'REQUEST_ACTIVE_SIM_GOI', // BUTTON YEU_CAU_KICH_HOAT_SIM_GOI
  FILTER_ACTIVE_SIM_GOI: 'FILTER_ACTIVE_SIM_GOI', // BO_LOC_DON_KICH_HOAT_SIM_GOI
  FILTER_ACTIVE_SIM_GOI_BY_CREATER: 'FILTER_ACTIVE_SIM_GOI_BY_CREATER', // BO_LOC_DON_KICH_HOAT_SIM_GOI_THEO_NGUOI_TAO
  ASSIGNEE_DETAIL_ACTIVE_SIM_GOI: 'ASSIGNEE_DETAIL_ACTIVE_SIM_GOI', // ASSIGNEE_DETAIL_DON_KICH_HOAT_SIM_GOI
  REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE: 'ACCEPT_BUTTON_DETAIL_ACTIVE', // TU_CHOI_DUYET_BUTTON_DETAIL_DON_KICH_HOAT_SIM_GOI
  OTHER_DOCUMENT_UPLOAD: 'OTHER_DOCUMENT_UPLOAD', // UPLOAD_TAI_LIEU_KHAC
  VIEW_OWNER_SIM_GOI: 'VIEW_OWNER_SIM_GOI', // XEM_CHU_SO_HUU_SIM_GOI

  // SIM_SO
  UPLOAD_LIST_SIM_SO: 'UPLOAD_LIST_SIM_SO', // UPLOAD_DANH_SACH_SIM
  APPROVE_LIST_UPLOAD_SIM_SO: 'APPROVE_LIST_UPLOAD_SIM_SO', // DANH_SACH_UPLOAD
  UPDATE_LIST_UPLOAD_SIM_SO: 'UPDATE_LIST_UPLOAD_SIM_SO', // PHE_DUYET_DANH_SACH_UPLOAD
  MANAGE_SIM_WAREHOUSE_SIM_SO: 'MANAGE_SIM_WAREHOUSE_SIM_SO', // QUAN_LY_KHO_SIM
  MANAGE_SIM_ACTIVATION_SIM_SO: 'MANAGE_SIM_ACTIVATION_SIM_SO', // QUAN_LY_KICH_HOAT_SIM
  EDIT_DETAIL_SIM_WAREHOUSE_SIM_SO: 'EDIT_DETAIL_SIM_WAREHOUSE_SIM_SO', // SUA_THONG_TIN_GOI_CUOC
  VISIBLE_SIM_SO: 'VISIBLE_SIM_SO',
  FILTER_SIM_SO: 'FILTER_SIM_SO', // BO_LOC_SIM_SO
  FILTER_BY_SELLER_SIM_SO: 'FILTER_BY_SELLER_SIM_SO', // LOC DANH SACH SIM GOI THEO SELLER

  // // SIM_SO detail
  RESTORE_LIST_SIM_SO: 'RESTORE_LIST_SIM_SO', // KHOI_PHUC_SIM_SO
  REMOVE_NUMBER_SIM_SO: 'REMOVE_NUMBER_SIM_SO', // GO_SO_SIM_SO
  EDIT_BUTTON_DETAIL_SIM_SO: 'EDIT_BUTTON_DETAIL_SIM_SO', // BUTTON_SUA_DETAIL_SIM_SO
  REMOVE_BUTTON_DETAIL_SIM_SO: 'REMOVE_BUTTON_DETAIL_SIM_SO', // BUTTON_GO_DETAIL_SIM_SO
  RESTORE_BUTTON_DETAIL_SIM_SO: 'RESTORE_BUTTON_DETAIL_SIM_SO', // BUTTON_KHOI_PHUC_DETAIL_SIM_SO
  VIEW_COMMISSION_RATE_SIM_SO: 'VIEW_COMMISSION_RATE_SIM_SO', // XEM_TI_LE_HOA_HONG_SIM_SO
  REQUEST_ACTIVE_SIM_SO: 'REQUEST_ACTIVE_SIM_SO', // YEU_CAU_KICH_HOAT_SIM_SO
  FILTER_ACTIVE_SIM_SO: 'FILTER_ACTIVE_SIM_SO', // BO_LOC_DON_KICH_HOAT_SIM_SO
  FILTER_ACTIVE_SIM_SO_BY_CREATER: 'FILTER_ACTIVE_SIM_SO_BY_CREATER', // BO_LOC_DON_KICH_HOAT_SIM_GOI_THEO_NGUOI_TAO
  FILTER_ACTIVE_SIM_SO_BY_SELLER: 'FILTER_ACTIVE_SIM_SO_BY_SELLER', // BO_LOC_DON_KICH_HOAT_SIM_SO_THEO_SELLER
  ASSIGNEE_DETAIL_ACTIVE_SIM_SO: 'ASSIGNEE_DETAIL_ACTIVE_SIM_SO', // ASSIGNEE_DETAIL_DON_KICH_HOAT_SIM_SO
  REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE_SIM_SO: 'REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE_SIM_SO', // TU_CHOI_DUYET_BUTTON_DETAIL_DON_KICH_HOAT_SIM_SO
  VIEW_OWNER_SIM_SO: 'VIEW_OWNER_SIM_SO', // XEM_CHU_SO_HUU_SIM_SO

  // SIM_M2M_&_DU_LICH
  UPLOAD_LIST_M2M_DU_LICH: 'UPLOAD_LIST_M2M_DU_LICH', // UPLOAD_DANH_SACH_SIM
  APPROVE_LIST_UPLOAD_M2M_DU_LICH: 'APPROVE_LIST_UPLOAD_M2M_DU_LICH', // XEM_DANH_SACH_UPLOAD
  UPDATE_LIST_UPLOAD_SIM_M2M_DU_LICH: 'UPDATE_LIST_UPLOAD_SIM_M2M_DU_LICH', // PHE_DUYET_DANH_SACH_UPLOAD
  MANAGE_SIM_WAREHOUSE_M2M_DU_LICH: 'MANAGE_SIM_WAREHOUSE_M2M_DU_LICH', // QUAN_LY_KHO_SIM
  MANAGE_SIM_ACTIVATION_M2M_DU_LICH: 'MANAGE_SIM_ACTIVATION_M2M_DU_LICH', // QUAN_LY_KICH_HOAT_SIM
  EDIT_DETAIL_SIM_WAREHOUSE_M2M_DU_LICH: 'EDIT_DETAIL_SIM_WAREHOUSE_M2M_DU_LICH', // SUA_THONG_TIN_GOI_CUOC
  VISIBLE_SIM_M2M_DU_LICH: 'VISIBLE_SIM_M2M_DU_LICH',
  VISIBLE_SIM_M2M: 'VISIBLE_SIM_M2M',
  FILTER_SIM_M2M_DU_LICH: 'FILTER_SIM_M2M_DU_LICH', // BO_LOC_SIM_M2M_DU_LICH
  FILTER_BY_SELLER_SIM_M2M_DU_LICH: 'FILTER_BY_SELLER_SIM_M2M_DU_LICH', // LOC DANH SACH SIM M2M DU  THEO SELLER
  // // SIM_M2M_&_DU_LICH detail
  RESTORE_LIST_SIM_M2M_DU_LICH: 'RESTORE_LIST_SIM_M2M_DU_LICH', // KHOI_PHUC_SIM_M2M_DU_LICH
  REMOVE_NUMBER_SIM_M2M_DU_LICH: 'REMOVE_NUMBER_SIM_M2M_DU_LICH', // GO_SO_SIM_M2M_DU_LICH
  EDIT_BUTTON_DETAIL_SIM_M2M_DU_LICH: 'EDIT_BUTTON_DETAIL_SIM_M2M_DU_LICH', // BUTTON_SUA_DETAIL_SIM_M2M_DU_LICH
  REMOVE_BUTTON_DETAIL_SIM_M2M_DU_LICH: 'REMOVE_BUTTON_DETAIL_SIM_M2M_DU_LICH', // BUTTON_GO_DETAIL_SIM_M2M_DU_LICH
  RESTORE_BUTTON_DETAIL_SIM_M2M_DU_LICH: 'RESTORE_BUTTON_DETAIL_SIM_M2M_DU_LICH', // BUTTON_KHOI_PHUC_DETAIL_SIM_M2M_DU_LICH
  VIEW_COMMISSION_RATE_SIM_M2M_DU_LICH: 'VIEW_COMMISSION_RATE_SIM_M2M_DU_LICH', // XEM_TI_LE_HOA_HONG_SIM_M2M_DU_LICH
  REQUEST_ACTIVE_SIM_M2M_DU_LICH: 'REQUEST_ACTIVE_SIM_M2M_DU_LICH', // BUTTON_YEU_CAU_KICH_HOAT_SIM_M2M_DU_LICH
  FILTER_ACTIVE_SIM_M2M_DU_LICH: 'FILTER_ACTIVE_SIM_M2M_DU_LICH', // BO_LOC_DON_KICH_HOAT_SIM_M2M_DU_LICH
  FILTER_ACTIVE_SIM_M2M_DU_LICH_BY_CREATER: 'FILTER_ACTIVE_SIM_M2M_DU_LICH_BY_CREATER', // BO_LOC_DON_KICH_HOAT_SIM_M2M_DU_LICH_THEO_NGUOI_TAO
  ASSIGNEE_DETAIL_ACTIVE_SIM_M2M_DU_LICH: 'ASSIGNEE_DETAIL_ACTIVE_SIM_M2M_DU_LICH', // ASSIGNEE_DETAIL_DON_KICH_HOAT_SIM_M2M_DU_LICH
  REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE_SIM_M2M_DU_LICH: 'REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE_SIM_M2M_DU_LICH', // TU_CHOI_DUYET_BUTTON_DETAIL_DON_KICH_HOAT_SIM_M2M_DU_LICH
  VIEW_OWNER_SIM_M2M_DU_LICH: 'VIEW_OWNER_SIM_M2M_DU_LICH', // XEM_CHU_SO_HUU_SIM_M2M_DU_LICH
  FILTER_BY_SELLER_ACTIVE_SIM_M2M_DU_LICH: 'FILTER_BY_SELLER_ACTIVE_SIM_M2M_DU_LICH', // BO_LOC_DON_KICH_HOAT_SIM_M2M_DU_LICH

  // QUAN_LY_GOI_CUOC
  GET_LIST_PACKAGED_SIM: 'GET_LIST_PACKAGED_SIM', // XEM_DANH_SACH_GOI_CUOC
  CREATE_PACKAGED_SIM: 'CREATE_PACKAGED_SIM', // THEM_GOI_CUOC
  UPDATE_PACKAGED_SIM: 'UPDATE_PACKAGED_SIM', // SUA_GOI_CUOC
  DELETE_PACKAGED_SIM: 'DELETE_PACKAGED_SIM', // XOA_GOI_CUOC
  GET_DETAIL_PACKAGED_SIM: 'GET_DETAIL_PACKAGED_SIM', // XEM_CHI_TIET_GOI_CUOC

  // QUAN_LY_WEBSITE
  VIEW_SETTING: 'VIEW_SETTING', // XEM_CAI_DAT
  VIEW_SETTING_DL_M2M_CHINA: 'VIEW_SETTING_DL_M2M_CHINA', // XEM_CAI_DAT
  VIEW_DETAIL: 'VIEW_DETAIL', // XEM_CAI_DAT
  EDIT_SETTING: 'EDIT_SETTING', // SUA_CAI_DAT
  CREATE_SETTING: 'CREATE_SETTING', // THEM_MOI_CAI_DAT

  // QUAN_LY_TAI_KHOAN
  VIEW_NAV_ACCOUNT: 'VIEW_NAV_ACCOUNT', // Nhìn thấy trên sidebar

  VIEW_LIST_INTERNAL_ACCOUNT: 'VIEW_LIST_INTERNAL_ACCOUNT', // XEM_DANH_SACH_TAI_KHOAN_NOI_BO
  VIEW_LIST_EXTERNAL_ACCOUNT: 'VIEW_LIST_EXTERNAL_ACCOUNT', // XEM_DANH_SACH_TAI_KHOAN_NGOAI
  VIEW_LIST_PARTNER_ACCOUNT: 'VIEW_LIST_PARTNER_ACCOUNT', // XEM_DANH_SACH_TAI_KHOAN_PARTNER

  UPDATE_STATUS_ACCOUNT: 'UPDATE_STATUS_ACCOUNT', // UPDATE_TRANG_THAI_TAI_KHOAN
  SEARCH_FILTER: 'SEARCH_FILTER', // SEARCH_FILTER
  VIEW_DETAIL_ACCOUNT: 'VIEW_DETAIL_ACCOUNT', // XEM_CHI_TIET_TAI_KHOAN
  EDIT_DETAIL_ACCOUNT: 'EDIT_DETAIL_ACCOUNT', // CHINH_SUA_TAI_KHOAN

  VIEW_DETAIL_PARTNER_ACCOUNT: 'VIEW_DETAIL_PARTNER_ACCOUNT', // XEM_CHI_TIET_TAI_KHOAN

  LIST_ACCOUNT_INTERNAL_LOWER_MANAGER: 'LIST_ACCOUNT_INTERNAL_LOWER_MANAGER', // TAI_KHOAN_NOI_BO_DUOI_QUEN_MANAGER
  LIST_ACCOUNT_INTERNAL_UPPER_MANAGER: 'LIST_ACCOUNT_INTERNAL_UPPER_MANAGER', // TAI_KHOAN_NOI_BO_TREN_QUEN_MANAGER
  CTV_ACCOUNT: 'CTV_ACCOUNT', // TAI_KHOAN_CTV
  DELETE_ACCOUNT: 'DELETE_ACCOUNT', // XOA_TAI_KHOAN
  EDIT_PARTNER_ACCOUNT: 'EDIT_PARTNER_ACCOUNT',
  EDIT_DETAIL_ACCOUNT_NHANVIEN: 'EDIT_DETAIL_ACCOUNT_NHANVIEN', // CHINH SUA TAI KHOAN CAP NHAN VIEN
  ACTIVE_DEACTIVE_EDIT_PARTNER: 'ACTIVE_DEACTIVE_EDIT_PARTNER',

  // QUAN_LY_TICKET
  VIEW_LIST_TICKET: 'VIEW_LIST_TICKET', // XEM_DANH_SACH_TICKET
  VIEW_DETAIL_TICKET: 'VIEW_DETAIL_TICKET', // XEM_CHI_TIET_TICKET
  UPDATE_STATUS_TICKET: 'UPDATE_STATUS_TICKET', // UPDATE_TRANG_THAI_TICKET

  // DON_HANG
  VIEW_LIST_ORDER: 'VIEW_LIST_ORDER', // XEM_DANH_SACH_DON_HANG
  FILTER_SEARCH_ORDER: 'FILTER_SEARCH_ORDER', // FILTER_SEARCH_DON_HANG
  VIEW_DETAIL_ORDER: 'VIEW_DETAIL_ORDER', // XEM_CHI_TIET_DON_HANG
  UPDATE_STATUS_ORDER: 'UPDATE_STATUS_ORDER', // UPDATE_TRANG_THAI_DON_HANG

  // QUAN_LY_SAN_PHAM
  VIEW_PRODUCT_PORTFOLIO: 'VIEW_PRODUCT_PORTFOLIO', // XEM_DANH_SACH_CHI_TIET_DANH_MUC_SAN_PHAM
  UPDATE_PRODUCT_PORTFOLIO: 'UPDATE_PRODUCT_PORTFOLIO', // CHINH_SUA_XOA_DANH_MUC_SAN_PHAM
  DELETE_PRODUCT_PORTFOLIO: 'DELETE_PRODUCT_PORTFOLIO', // XOA_DANH_MUC_SAN_PHAM
  VIEW_LIST_PRODUCT: 'VIEW_LIST_PRODUCT', // XEM_DANH_SACH_SAN_PHAM
  SEARCH_FILTER_PRODUCT: 'SEARCH_FILTER_PRODUCT', // SEARCH_FILTER_DANH_SACH_SAN_PHAM
  WARRANTY_ACTIVATION: 'WARRANTY_ACTIVATION', // KICH_HOAT_BAO_HANH
  VIEW_DETAIL_PRODUCT: 'VIEW_DETAIL_PRODUCT', // XEM_CHI_TIET_SAN_PHAM
  CREATE_UPDATE_PRODUCT: 'CREATE_UPDATE_PRODUCT', // TAO_MOI_CHINH_SUA_SAN_PHAM
  CREATE_IMPORT_REQUEST: 'CREATE_IMPORT_REQUEST', // TAO_DON_YEU_CAU_NHAP_KHO
  CREATE_EXPORT_REQUEST: 'CREATE_EXPORT_REQUEST', // TAO_DON_YEU_CAU_XUAT_KHO
  VIEW_IMPORT_REQUEST: 'VIEW_IMPORT_REQUEST',
  APPROVE_IMPORT_EXPORT_REQUEST: 'APPROVE_IMPORT_EXPORT_REQUEST', // PHE_DUYET_YEU_CAU_XUAT_NHAP_KHO
  CREATE_IMPORT_EXPORT_WARRANTY_MANAGEMENT_REQUEST: 'CREATE_IMPORT_EXPORT_WARRANTY_MANAGEMENT_REQUEST', // TAO_DON_YEU_CAU_XUAT_NHAP_QUAN_LY_BAO_HANH
  VIEW_WARRANTY_MANAGEMENT_REQUEST: 'VIEW_WARRANTY_MANAGEMENT_REQUEST', // XEM_DON_YEU_CAU_XUAT_NHAP_QUAN_LY_BAO_HANH
  APPROVE_IMPORT_EXPORT_WARRANTY_MANAGEMENT_REQUEST: 'APPROVE_IMPORT_EXPORT_WARRANTY_MANAGEMENT_REQUEST', // PHE_DUYET_YEU_CAU_XUAT_NHAP_QUAN_LY_BAO_HANH
  TOPUP_ORDERS_MANAGEMENT: 'TOPUP_ORDERS_MANAGEMENT', // XEM_DANH_SACH_DON_HANG_NAP_THE
  PROVIDER_ORDERS_MANAGEMENT: 'PROVIDER_ORDERS_MANAGEMENT', // XEM_DANH_SACH_DON_HANG_NHA_MANG

  // GIA HẠN GÓI CƯỚC SIM SỐ
  VIEW_EXTEND_ATTRACTIVE_SIM: 'VIEW_EXTEND_ATTRACTIVE_SIM', // XEM_DANH_SACH_GIA_HAN_GOI_CUOC_SIM_SO
  VIEW_DETAIL_EXTEND_SIM: 'VIEW_DETAIL_EXTEND_SIM', // XEM_CHI_TIET_GIA_HAN_GOI_CUOC_SIM_SO
  VIEW_ARCHIVED_EXTEND_ATTRACTIVE_SIM: 'VIEW_ARCHIVED_EXTEND_ATTRACTIVE_SIM', // XEM_DANH_SACH_GIA_HAN_GOI_CUOC_SIM_SO_DA_LUU_TRU
  CHANGE_CSKH_ASSIGNMENT: 'CHANGE_CSKH_ASSIGNMENT', // THAY_ĐỔI_CSKH
  CHANGE_TECHNICAL_ASSIGNMENT: 'CHANGE_TECHNICAL_ASSIGNMENT', // THAY_ĐỔI_KỸ_THUẬT

  // UPLOAD TEMPLATE SIM
  UPLOAD_TEMPLATE_SIM: 'UPLOAD_TEMPLATE_SIM', // UPLOAD_TEMPLATE_SIM

  // THIET LAP CHUNG
  DISCOUNT_CARD_MANAGEMENT: 'DISCOUNT_CARD_MANAGEMENT',
  EDIT_CUMULATIVE_POINTS: 'EDIT_CUMULATIVE_POINTS',

  // XUAT BAO CAO SIM
  EXPORT_REPORT_SIM: 'EXPORT_REPORT_SIM',

  // HOAN TIEN
  REFUND_MONEY_CARD: 'REFUND_MONEY_CARD',

  // Nạp bù
  TOPUP_MISSING_CARD: 'TOPUP_MISSING_CARD',

  WHITE_LIST_SETTING: 'WHITE_LIST_SETTING',

  // BLOG
  VIEW_NAVIGATE_BLOG: 'VIEW_NAVIGATE_BLOG', // THẤY NAVIGATION BLOG
  VIEW_LIST_BLOG: 'VIEW_LIST_BLOG', // XEM_DANH_SACH_BLOG
  ACTION_LIST_BLOG: 'ACTION_LIST_BLOG', // THAO TAC TREN TRANG DANH SACH
  ACTION_DETAIL_BLOG: 'ACTION_DETAIL_BLOG', // THAO TAC TREN TRANG CHI TIET
  DELETE_BLOG_OTHER_USER: 'DELETE_BLOG_OTHER_USER', // XOA BLOG NGUOI KHAC
  EDIT_BLOG_OTHER_USER: 'EDIT_BLOG_OTHER_USER', // SUA BLOG NGUOI KHAC
  EDIT_BLOG_OWN: 'EDIT_BLOG_OWN', // SUA BLOG CUA MINH
  DELETE_BLOG_OWN: 'DELETE_BLOG_OWN', // XOA BLOG CUA MINH

  // XUAT BAO CAO THE NAP
  EXPORT_CARD: 'EXPORT_CARD',

  // XUAT BAO CAO GIA HAN
  EXPORT_RENEWALS: 'EXPORT_RENEWALS',

  // DOI SOAT
  CROSS_CHECK: 'CROSS_CHECK',

  // FLASH_SALE
  FLASH_SALE: 'FLASH_SALE',

  // THONG KE KINH DOANH
  VIEW_STATISTIC: 'VIEW_STATISTIC',
}

const ADMIN = {
  ...PERMISSION,
}

const STAFF = {

}

const MANAGING_ADMIN = {
  // // QUAN_LY_GOI_CUOC
  // GET_LIST_PACKAGED_SIM: PERMISSION.GET_LIST_PACKAGED_SIM,
  // CREATE_PACKAGED_SIM: PERMISSION.CREATE_PACKAGED_SIM,
  // UPDATE_PACKAGED_SIM: PERMISSION.UPDATE_PACKAGED_SIM,
  // DELETE_PACKAGED_SIM: PERMISSION.DELETE_PACKAGED_SIM,
  // GET_DETAIL_PACKAGED_SIM: PERMISSION.GET_DETAIL_PACKAGED_SIM,

  // admin permission
  ...ADMIN,
}

// Trưởng phòng hành chính
const ADMINISTRATIVE_MANAGER = {
  // SIM_GOI
  UPLOAD_LIST_SIM_GOI: PERMISSION.UPLOAD_LIST_SIM_GOI, // UPLOAD_DANH_SACH_SIM
  MANAGE_SIM_WAREHOUSE_SIM_GOI: PERMISSION.MANAGE_SIM_WAREHOUSE_SIM_GOI,
  VISIBLE_SIM_GOI: PERMISSION.VISIBLE_SIM_GOI,
  APPROVE_LIST_UPLOAD_SIM_GOI: PERMISSION.APPROVE_LIST_UPLOAD_SIM_GOI,
  // UPDATE_LIST_UPLOAD_SIM_GOI: PERMISSION.UPDATE_LIST_UPLOAD_SIM_GOI,
  VIEW_OWNER_SIM_GOI: PERMISSION.VIEW_OWNER_SIM_GOI,
  FILTER_SIM_GOI: PERMISSION.FILTER_SIM_GOI,
  FILTER_BY_SELLER_SIM_PACKAGED: PERMISSION.FILTER_BY_SELLER_SIM_PACKAGED,
  REMOVE_BUTTON_DETAIL_SIM_GOI: PERMISSION.REMOVE_BUTTON_DETAIL_SIM_GOI,
  RESTORE_BUTTON_DETAIL_SIM_GOI: PERMISSION.RESTORE_BUTTON_DETAIL_SIM_GOI,
  EDIT_BUTTON_DETAIL_SIM_GOI: PERMISSION.EDIT_BUTTON_DETAIL_SIM_GOI,
  RESTORE_LIST_SIM_GOI: PERMISSION.RESTORE_LIST_SIM_GOI,
  REMOVE_NUMBER_SIM_GOI: PERMISSION.REMOVE_NUMBER_SIM_GOI,
  VIEW_COMMISSION_RATE: PERMISSION.VIEW_COMMISSION_RATE, // XEM_TI_LE_HOA_HONG_SIM_GOI
  EDIT_DETAIL_SIM_WAREHOUSE_SIM_SO: PERMISSION.EDIT_DETAIL_SIM_WAREHOUSE_SIM_SO,

  // SIM_SO
  UPLOAD_LIST_SIM_SO: PERMISSION.UPLOAD_LIST_SIM_SO, // UPLOAD_DANH_SACH_SIM
  MANAGE_SIM_WAREHOUSE_SIM_SO: PERMISSION.MANAGE_SIM_WAREHOUSE_SIM_SO,
  VISIBLE_SIM_SO: PERMISSION.VISIBLE_SIM_SO,
  APPROVE_LIST_UPLOAD_SIM_SO: PERMISSION.APPROVE_LIST_UPLOAD_SIM_SO,
  // UPDATE_LIST_UPLOAD_SIM_SO: PERMISSION.UPDATE_LIST_UPLOAD_SIM_SO,
  VIEW_OWNER_SIM_SO: PERMISSION.VIEW_OWNER_SIM_SO,
  FILTER_SIM_SO: PERMISSION.FILTER_SIM_SO,
  FILTER_BY_SELLER_SIM_SO: PERMISSION.FILTER_BY_SELLER_SIM_SO,
  REMOVE_BUTTON_DETAIL_SIM_SO: PERMISSION.REMOVE_BUTTON_DETAIL_SIM_SO,
  RESTORE_BUTTON_DETAIL_SIM_SO: PERMISSION.RESTORE_BUTTON_DETAIL_SIM_SO,
  EDIT_BUTTON_DETAIL_SIM_SO: PERMISSION.EDIT_BUTTON_DETAIL_SIM_SO,
  RESTORE_LIST_SIM_SO: PERMISSION.RESTORE_LIST_SIM_SO,
  REMOVE_NUMBER_SIM_SO: PERMISSION.REMOVE_NUMBER_SIM_SO,
  VIEW_COMMISSION_RATE_SIM_SO: PERMISSION.VIEW_COMMISSION_RATE_SIM_SO,
  EDIT_DETAIL_SIM_WAREHOUSE_SIM_GOI: PERMISSION.EDIT_DETAIL_SIM_WAREHOUSE_SIM_GOI,

  // SIM_M2M_&_DU_LICH
  APPROVE_LIST_UPLOAD_M2M_DU_LICH: PERMISSION.APPROVE_LIST_UPLOAD_M2M_DU_LICH,
  UPLOAD_LIST_M2M_DU_LICH: PERMISSION.UPLOAD_LIST_M2M_DU_LICH, // UPLOAD_DANH_SACH_SIM
  MANAGE_SIM_WAREHOUSE_M2M_DU_LICH: PERMISSION.MANAGE_SIM_WAREHOUSE_M2M_DU_LICH,
  VISIBLE_SIM_M2M_DU_LICH: PERMISSION.VISIBLE_SIM_M2M_DU_LICH,
  VIEW_OWNER_SIM_M2M_DU_LICH: PERMISSION.VIEW_OWNER_SIM_M2M_DU_LICH,
  FILTER_SIM_M2M_DU_LICH: PERMISSION.FILTER_SIM_M2M_DU_LICH,
  FILTER_BY_SELLER_SIM_M2M_DU_LICH: PERMISSION.FILTER_BY_SELLER_SIM_M2M_DU_LICH,
  REMOVE_BUTTON_DETAIL_SIM_M2M_DU_LICH: PERMISSION.REMOVE_BUTTON_DETAIL_SIM_M2M_DU_LICH,
  RESTORE_BUTTON_DETAIL_SIM_M2M_DU_LICH: PERMISSION.RESTORE_BUTTON_DETAIL_SIM_M2M_DU_LICH,
  EDIT_BUTTON_DETAIL_SIM_M2M_DU_LICH: PERMISSION.EDIT_BUTTON_DETAIL_SIM_M2M_DU_LICH,
  RESTORE_LIST_SIM_M2M_DU_LICH: PERMISSION.RESTORE_LIST_SIM_M2M_DU_LICH,
  REMOVE_NUMBER_SIM_M2M_DU_LICH: PERMISSION.REMOVE_NUMBER_SIM_M2M_DU_LICH,
  VIEW_COMMISSION_RATE_SIM_M2M_DU_LICH: PERMISSION.VIEW_COMMISSION_RATE_SIM_M2M_DU_LICH,
  EDIT_DETAIL_SIM_WAREHOUSE_M2M_DU_LICH: PERMISSION.EDIT_DETAIL_SIM_WAREHOUSE_M2M_DU_LICH,

  // QUAN_LY_TAI_KHOAN
  VIEW_NAV_ACCOUNT: PERMISSION.VIEW_NAV_ACCOUNT,
  VIEW_LIST_EXTERNAL_ACCOUNT: PERMISSION.VIEW_LIST_EXTERNAL_ACCOUNT,
  VIEW_LIST_PARTNER_ACCOUNT: PERMISSION.VIEW_LIST_PARTNER_ACCOUNT,
  VIEW_LIST_INTERNAL_ACCOUNT: PERMISSION.VIEW_LIST_INTERNAL_ACCOUNT,
  UPDATE_STATUS_ACCOUNT: PERMISSION.UPDATE_STATUS_ACCOUNT,
  SEARCH_FILTER: PERMISSION.SEARCH_FILTER,
  VIEW_DETAIL_ACCOUNT: PERMISSION.VIEW_DETAIL_ACCOUNT,
  EDIT_DETAIL_ACCOUNT: PERMISSION.EDIT_DETAIL_ACCOUNT,
  LIST_ACCOUNT_INTERNAL_LOWER_MANAGER: PERMISSION.LIST_ACCOUNT_INTERNAL_LOWER_MANAGER,
  LIST_ACCOUNT_INTERNAL_UPPER_MANAGER: PERMISSION.LIST_ACCOUNT_INTERNAL_UPPER_MANAGER,
  CTV_ACCOUNT: PERMISSION.CTV_ACCOUNT,
  EDIT_PARTNER_ACCOUNT: PERMISSION.EDIT_PARTNER_ACCOUNT,
  ACTIVE_DEACTIVE_EDIT_PARTNER: PERMISSION.ACTIVE_DEACTIVE_EDIT_PARTNER,

  // QUAN_LY_TICKET
  VIEW_LIST_TICKET: PERMISSION.VIEW_LIST_TICKET,
  VIEW_DETAIL_TICKET: PERMISSION.VIEW_DETAIL_TICKET,
  // UPDATE_STATUS_TICKET: PERMISSION.UPDATE_STATUS_TICKET,

  // // DON_HANG
  // VIEW_LIST_ORDER: PERMISSION.VIEW_LIST_ORDER,
  // FILTER_SEARCH_ORDER: PERMISSION.FILTER_SEARCH_ORDER,
  // VIEW_DETAIL_ORDER: PERMISSION.VIEW_DETAIL_ORDER,
  // UPDATE_STATUS_ORDER: PERMISSION.UPDATE_STATUS_ORDER,
  // PROVIDER_ORDERS_MANAGEMENT: PERMISSION.PROVIDER_ORDERS_MANAGEMENT,

  // QUAN_LY_SAN_PHAM
  VIEW_PRODUCT_PORTFOLIO: PERMISSION.VIEW_PRODUCT_PORTFOLIO,
  UPDATE_PRODUCT_PORTFOLIO: PERMISSION.UPDATE_PRODUCT_PORTFOLIO,
  VIEW_LIST_PRODUCT: PERMISSION.VIEW_LIST_PRODUCT,
  SEARCH_FILTER_PRODUCT: PERMISSION.SEARCH_FILTER_PRODUCT,
  VIEW_DETAIL_PRODUCT: PERMISSION.VIEW_DETAIL_PRODUCT,
  // CREATE_UPDATE_PRODUCT: PERMISSION.CREATE_UPDATE_PRODUCT,
  APPROVE_IMPORT_EXPORT_REQUEST: PERMISSION.APPROVE_IMPORT_EXPORT_REQUEST,
  // CREATE_IMPORT_EXPORT_WARRANTY_MANAGEMENT_REQUEST: PERMISSION.CREATE_IMPORT_EXPORT_WARRANTY_MANAGEMENT_REQUEST,
  APPROVE_IMPORT_EXPORT_WARRANTY_MANAGEMENT_REQUEST: PERMISSION.APPROVE_IMPORT_EXPORT_WARRANTY_MANAGEMENT_REQUEST,

  // UPLOAD TEMPLATE SIM
  UPLOAD_TEMPLATE_SIM: PERMISSION.UPLOAD_TEMPLATE_SIM,

  // XUAT BAO CAO SIM
  EXPORT_REPORT_SIM: PERMISSION.EXPORT_REPORT_SIM,

  // Quản lý điểm thưởng
  MANGAGE_BONUS: PERMISSION.MANGAGE_BONUS,
  APPROVE_BONUS: PERMISSION.APPROVE_BONUS, // PHE DUYET DIEM THUONG
  VIEW_REQUEST_CHANGE_POINT: PERMISSION.VIEW_REQUEST_CHANGE_POINT, // Xem danh sách yêu cầu đổi điểm

  // XUAT BAO CAO THE NAP
  EXPORT_CARD: PERMISSION.EXPORT_CARD,

  // XUAT BAO CAO GIA HAN
  EXPORT_RENEWALS: 'EXPORT_RENEWALS',

  // staff permission
  ...STAFF,
}

// Nhân viên hành chính
const ADMINISTRATIVE_ACCOUNT = {
  // QUAN_LY_TAI_KHOAN
  VIEW_NAV_ACCOUNT: PERMISSION.VIEW_NAV_ACCOUNT,
  VIEW_LIST_EXTERNAL_ACCOUNT: PERMISSION.VIEW_LIST_EXTERNAL_ACCOUNT,
  VIEW_LIST_INTERNAL_ACCOUNT: PERMISSION.VIEW_LIST_INTERNAL_ACCOUNT,
  SEARCH_FILTER: PERMISSION.SEARCH_FILTER,
  VIEW_DETAIL_ACCOUNT: PERMISSION.VIEW_DETAIL_ACCOUNT,
  LIST_ACCOUNT_INTERNAL_LOWER_MANAGER: PERMISSION.LIST_ACCOUNT_INTERNAL_LOWER_MANAGER,
  CTV_ACCOUNT: PERMISSION.CTV_ACCOUNT,
  // staff permission
  ...STAFF,
}

// Nhân viên kho
const WAREHOUSE_ACCOUNT = {
  // SIM_GOI
  UPLOAD_LIST_SIM_GOI: PERMISSION.UPLOAD_LIST_SIM_GOI, // UPLOAD_DANH_SACH_SIM
  MANAGE_SIM_WAREHOUSE_SIM_GOI: PERMISSION.MANAGE_SIM_WAREHOUSE_SIM_GOI,
  VISIBLE_SIM_GOI: PERMISSION.VISIBLE_SIM_GOI,
  APPROVE_LIST_UPLOAD_SIM_GOI: PERMISSION.APPROVE_LIST_UPLOAD_SIM_GOI,
  UPDATE_LIST_UPLOAD_SIM_GOI: PERMISSION.UPDATE_LIST_UPLOAD_SIM_GOI,
  FILTER_SIM_GOI: PERMISSION.FILTER_SIM_GOI,
  FILTER_BY_SELLER_SIM_PACKAGED: PERMISSION.FILTER_BY_SELLER_SIM_PACKAGED,
  REMOVE_BUTTON_DETAIL_SIM_GOI: PERMISSION.REMOVE_BUTTON_DETAIL_SIM_GOI,
  RESTORE_BUTTON_DETAIL_SIM_GOI: PERMISSION.RESTORE_BUTTON_DETAIL_SIM_GOI,
  EDIT_BUTTON_DETAIL_SIM_GOI: PERMISSION.EDIT_BUTTON_DETAIL_SIM_GOI,
  RESTORE_LIST_SIM_GOI: PERMISSION.RESTORE_LIST_SIM_GOI,
  REMOVE_NUMBER_SIM_GOI: PERMISSION.REMOVE_NUMBER_SIM_GOI,
  VIEW_COMMISSION_RATE: PERMISSION.VIEW_COMMISSION_RATE,
  EDIT_DETAIL_SIM_WAREHOUSE_SIM_GOI: PERMISSION.EDIT_DETAIL_SIM_WAREHOUSE_SIM_GOI,
  // SIM_SO
  UPLOAD_LIST_SIM_SO: PERMISSION.UPLOAD_LIST_SIM_SO, // UPLOAD_DANH_SACH_SIM
  MANAGE_SIM_WAREHOUSE_SIM_SO: PERMISSION.MANAGE_SIM_WAREHOUSE_SIM_SO,
  VISIBLE_SIM_SO: PERMISSION.VISIBLE_SIM_SO,
  APPROVE_LIST_UPLOAD_SIM_SO: PERMISSION.APPROVE_LIST_UPLOAD_SIM_SO,
  UPDATE_LIST_UPLOAD_SIM_SO: PERMISSION.UPDATE_LIST_UPLOAD_SIM_SO,
  FILTER_SIM_SO: PERMISSION.FILTER_SIM_SO,
  FILTER_BY_SELLER_SIM_SO: PERMISSION.FILTER_BY_SELLER_SIM_SO,
  REMOVE_BUTTON_DETAIL_SIM_SO: PERMISSION.REMOVE_BUTTON_DETAIL_SIM_SO,
  RESTORE_BUTTON_DETAIL_SIM_SO: PERMISSION.RESTORE_BUTTON_DETAIL_SIM_SO,
  EDIT_BUTTON_DETAIL_SIM_SO: PERMISSION.EDIT_BUTTON_DETAIL_SIM_SO,
  RESTORE_LIST_SIM_SO: PERMISSION.RESTORE_LIST_SIM_SO,
  REMOVE_NUMBER_SIM_SO: PERMISSION.REMOVE_NUMBER_SIM_SO,
  VIEW_COMMISSION_RATE_SIM_SO: PERMISSION.VIEW_COMMISSION_RATE_SIM_SO,
  EDIT_DETAIL_SIM_WAREHOUSE_SIM_SO: PERMISSION.EDIT_DETAIL_SIM_WAREHOUSE_SIM_SO,

  // SIM_M2M_&_DU_LICH
  UPLOAD_LIST_M2M_DU_LICH: PERMISSION.UPLOAD_LIST_M2M_DU_LICH, // UPLOAD_DANH_SACH_SIM
  MANAGE_SIM_WAREHOUSE_M2M_DU_LICH: PERMISSION.MANAGE_SIM_WAREHOUSE_M2M_DU_LICH,
  VISIBLE_SIM_M2M_DU_LICH: PERMISSION.VISIBLE_SIM_M2M_DU_LICH,
  APPROVE_LIST_UPLOAD_M2M_DU_LICH: PERMISSION.APPROVE_LIST_UPLOAD_M2M_DU_LICH,
  FILTER_SIM_M2M_DU_LICH: PERMISSION.FILTER_SIM_M2M_DU_LICH,
  FILTER_BY_SELLER_SIM_M2M_DU_LICH: PERMISSION.FILTER_BY_SELLER_SIM_M2M_DU_LICH,
  REMOVE_BUTTON_DETAIL_SIM_M2M_DU_LICH: PERMISSION.REMOVE_BUTTON_DETAIL_SIM_M2M_DU_LICH,
  RESTORE_BUTTON_DETAIL_SIM_M2M_DU_LICH: PERMISSION.RESTORE_BUTTON_DETAIL_SIM_M2M_DU_LICH,
  EDIT_BUTTON_DETAIL_SIM_M2M_DU_LICH: PERMISSION.EDIT_BUTTON_DETAIL_SIM_M2M_DU_LICH,
  RESTORE_LIST_SIM_M2M_DU_LICH: PERMISSION.RESTORE_LIST_SIM_M2M_DU_LICH,
  REMOVE_NUMBER_SIM_M2M_DU_LICH: PERMISSION.REMOVE_NUMBER_SIM_M2M_DU_LICH,
  VIEW_COMMISSION_RATE_SIM_M2M_DU_LICH: PERMISSION.VIEW_COMMISSION_RATE_SIM_M2M_DU_LICH,
  EDIT_DETAIL_SIM_WAREHOUSE_M2M_DU_LICH: PERMISSION.EDIT_DETAIL_SIM_WAREHOUSE_M2M_DU_LICH,

  // DON_HANG
  VIEW_LIST_ORDER: PERMISSION.VIEW_LIST_ORDER,
  FILTER_SEARCH_ORDER: PERMISSION.FILTER_SEARCH_ORDER,
  VIEW_DETAIL_ORDER: PERMISSION.VIEW_DETAIL_ORDER,
  UPDATE_STATUS_ORDER: PERMISSION.UPDATE_STATUS_ORDER,

  // QUAN_LY_SAN_PHAM
  VIEW_PRODUCT_PORTFOLIO: PERMISSION.VIEW_PRODUCT_PORTFOLIO,
  UPDATE_PRODUCT_PORTFOLIO: PERMISSION.UPDATE_PRODUCT_PORTFOLIO,
  VIEW_LIST_PRODUCT: PERMISSION.VIEW_LIST_PRODUCT,
  SEARCH_FILTER_PRODUCT: PERMISSION.SEARCH_FILTER_PRODUCT,
  VIEW_DETAIL_PRODUCT: PERMISSION.VIEW_DETAIL_PRODUCT,
  CREATE_UPDATE_PRODUCT: PERMISSION.CREATE_UPDATE_PRODUCT,
  CREATE_IMPORT_EXPORT_WARRANTY_MANAGEMENT_REQUEST: PERMISSION.CREATE_IMPORT_EXPORT_WARRANTY_MANAGEMENT_REQUEST,
  CREATE_EXPORT_REQUEST: PERMISSION.CREATE_EXPORT_REQUEST,

  UPLOAD_TEMPLATE_SIM: PERMISSION.UPLOAD_TEMPLATE_SIM, // UPLOAD_TEMPLATE_SIM

  // QUAN_LY_ACCOUNT
  VIEW_NAV_ACCOUNT: PERMISSION.VIEW_NAV_ACCOUNT,
  VIEW_LIST_PARTNER_ACCOUNT: PERMISSION.VIEW_LIST_PARTNER_ACCOUNT,
  VIEW_LIST_EXTERNAL_ACCOUNT: PERMISSION.VIEW_LIST_EXTERNAL_ACCOUNT,
  VIEW_LIST_INTERNAL_ACCOUNT: PERMISSION.VIEW_LIST_INTERNAL_ACCOUNT,

}

const ADDITIONAL_PROFILE_ACCOUNT = {
  // navbar
  VISIBLE_SIM_SO: PERMISSION.VISIBLE_SIM_SO,
  VISIBLE_SIM_GOI: PERMISSION.VISIBLE_SIM_GOI,
  VISIBLE_SIM_M2M_DU_LICH: PERMISSION.VISIBLE_SIM_M2M_DU_LICH,

  MANAGE_SIM_WAREHOUSE_SIM_SO: PERMISSION.MANAGE_SIM_WAREHOUSE_SIM_SO,
  MANAGE_SIM_WAREHOUSE_SIM_GOI: PERMISSION.MANAGE_SIM_WAREHOUSE_SIM_GOI,
  MANAGE_SIM_WAREHOUSE_M2M_DU_LICH: PERMISSION.MANAGE_SIM_WAREHOUSE_M2M_DU_LICH,

  MANAGE_SIM_ACTIVATION_SIM_SO: PERMISSION.MANAGE_SIM_ACTIVATION_SIM_SO,
  MANAGE_SIM_ACTIVATION_SIM_GOI: PERMISSION.MANAGE_SIM_ACTIVATION_SIM_GOI,
  MANAGE_SIM_ACTIVATION_M2M_DU_LICH: PERMISSION.MANAGE_SIM_ACTIVATION_M2M_DU_LICH,

  REQUEST_ACTIVE_SIM_SO: PERMISSION.REQUEST_ACTIVE_SIM_SO,
  REQUEST_ACTIVE_SIM_GOI: PERMISSION.REQUEST_ACTIVE_SIM_GOI,
  REQUEST_ACTIVE_SIM_M2M_DU_LICH: PERMISSION.REQUEST_ACTIVE_SIM_M2M_DU_LICH,

  FILTER_ACTIVE_SIM_GOI: PERMISSION.FILTER_ACTIVE_SIM_GOI,
  FILTER_ACTIVE_SIM_SO: PERMISSION.FILTER_ACTIVE_SIM_SO,
  FILTER_ACTIVE_SIM_M2M_DU_LICH: PERMISSION.FILTER_ACTIVE_SIM_M2M_DU_LICH,
  FILTER_ACTIVE_SIM_GOI_BY_CREATER: PERMISSION.FILTER_ACTIVE_SIM_GOI_BY_CREATER,
  FILTER_ACTIVE_SIM_SO_BY_CREATER: PERMISSION.FILTER_ACTIVE_SIM_SO_BY_CREATER,
  FILTER_ACTIVE_SIM_M2M_DU_LICH_BY_CREATER: PERMISSION.FILTER_ACTIVE_SIM_M2M_DU_LICH_BY_CREATER,
  FILTER_ACTIVE_SIM_SO_BY_SELLER: PERMISSION.FILTER_ACTIVE_SIM_SO_BY_SELLER,
  FILTER_BY_SELLER_ACTIVE_SIM_M2M_DU_LICH: PERMISSION.FILTER_BY_SELLER_ACTIVE_SIM_M2M_DU_LICH,

  FILTER_SIM_GOI: PERMISSION.FILTER_SIM_GOI,
  FILTER_BY_SELLER_SIM_PACKAGED: PERMISSION.FILTER_BY_SELLER_SIM_PACKAGED,
  FILTER_SIM_SO: PERMISSION.FILTER_SIM_SO,
  FILTER_BY_SELLER_SIM_SO: PERMISSION.FILTER_BY_SELLER_SIM_SO,
  FILTER_SIM_M2M_DU_LICH: PERMISSION.FILTER_SIM_M2M_DU_LICH,
  FILTER_BY_SELLER_SIM_M2M_DU_LICH: PERMISSION.FILTER_BY_SELLER_SIM_M2M_DU_LICH,

  // staff permission
  ...STAFF,
}

// Nhân viên Kế toán
const ACCOUNTANT_ACCOUNT = {

  // XUAT BAO CAO SIM
  EXPORT_REPORT_SIM: PERMISSION.EXPORT_REPORT_SIM,

  // XUAT BAO CAO THE NAP
  EXPORT_CARD: PERMISSION.EXPORT_CARD,

  // XUAT BAO CAO GIA HAN
  EXPORT_RENEWALS: 'EXPORT_RENEWALS',

  // Quan ly diem thuong
  MANGAGE_BONUS: PERMISSION.MANGAGE_BONUS,
  VIEW_REQUEST_CHANGE_POINT: PERMISSION.VIEW_REQUEST_CHANGE_POINT, // Xem danh sách yêu cầu đổi điểm

  // sim gói
  MANAGE_SIM_WAREHOUSE_SIM_GOI: PERMISSION.MANAGE_SIM_WAREHOUSE_SIM_GOI,
  VIEW_COMMISSION_RATE: PERMISSION.VIEW_COMMISSION_RATE, // Xem tỉ lệ hoa hông sim gói
  // REQUEST_ACTIVE_SIM_GOI: PERMISSION.REQUEST_ACTIVE_SIM_GOI, // Xem đơn yêu cầu kích hoạt sim gói
  FILTER_SIM_GOI: PERMISSION.FILTER_SIM_GOI,
  FILTER_BY_SELLER_SIM_PACKAGED: PERMISSION.FILTER_BY_SELLER_SIM_PACKAGED,
  MANAGE_SIM_ACTIVATION_SIM_GOI: PERMISSION.MANAGE_SIM_ACTIVATION_SIM_GOI,
  VISIBLE_SIM_GOI: PERMISSION.VISIBLE_SIM_GOI,

  // sim số
  MANAGE_SIM_WAREHOUSE_SIM_SO: PERMISSION.MANAGE_SIM_WAREHOUSE_SIM_SO,
  VIEW_COMMISION_RATE_SIM_SO: PERMISSION.VIEW_COMMISSION_RATE_SIM_SO, // Xem tỉ lệ hoa hông sim số
  // REQUEST_ACTIVE_SIM_SO: PERMISSION.REQUEST_ACTIVE_SIM_SO, // Xem đơn yêu cầu kích hoạt sim gói
  FILTER_SIM_SO: PERMISSION.FILTER_SIM_SO,
  FILTER_BY_SELLER_SIM_SO: PERMISSION.FILTER_BY_SELLER_SIM_SO,
  MANAGE_SIM_ACTIVATION_SIM_SO: PERMISSION.MANAGE_SIM_ACTIVATION_SIM_SO,
  VISIBLE_SIM_SO: PERMISSION.VISIBLE_SIM_SO,

  // Sim m2m sim du lịch
  MANAGE_SIM_WAREHOUSE_M2M_DU_LICH: PERMISSION.MANAGE_SIM_WAREHOUSE_M2M_DU_LICH,
  VIEW_COMMISSION_RATE_SIM_M2M_DU_LICH: PERMISSION.VIEW_COMMISSION_RATE_SIM_M2M_DU_LICH,
  // REQUEST_ACTIVE_SIM_M2M_DU_LICH: PERMISSION.REQUEST_ACTIVE_SIM_M2M_DU_LICH, // Xem đơn yêu cầu kích hoạt sim gói
  FILTER_SIM_M2M_DU_LICH: PERMISSION.FILTER_SIM_M2M_DU_LICH,
  FILTER_BY_SELLER_SIM_M2M_DU_LICH: PERMISSION.FILTER_BY_SELLER_SIM_M2M_DU_LICH,
  MANAGE_SIM_ACTIVATION_M2M_DU_LICH: PERMISSION.MANAGE_SIM_ACTIVATION_M2M_DU_LICH,
  VISIBLE_SIM_M2M_DU_LICH: PERMISSION.VISIBLE_SIM_M2M_DU_LICH,

  // Gia hạn gói cước
  VIEW_EXTEND_ATTRACTIVE_SIM: 'VIEW_EXTEND_ATTRACTIVE_SIM', // XEM_DANH_SACH_GIA_HAN_GOI_CUOC_SIM_SO
  VIEW_ARCHIVED_EXTEND_ATTRACTIVE_SIM: 'VIEW_ARCHIVED_EXTEND_ATTRACTIVE_SIM', // XEM_DANH_SACH_GIA_HAN_GOI_CUOC_SIM_SO_DA_LUU_TRU

  // SAN PHAM
  CREATE_EXPORT_REQUEST: PERMISSION.CREATE_EXPORT_REQUEST,
  VIEW_IMPORT_REQUEST: PERMISSION.VIEW_IMPORT_REQUEST,
  APPROVE_IMPORT_EXPORT_REQUEST: PERMISSION.APPROVE_IMPORT_EXPORT_REQUEST,

  // Đối soát
  CROSS_CHECK: PERMISSION.CROSS_CHECK,

  // View nạp thẻ
  TOPUP_ORDERS_MANAGEMENT: PERMISSION.TOPUP_ORDERS_MANAGEMENT,
  VIEW_LIST_ORDER: PERMISSION.VIEW_LIST_ORDER,

  ...STAFF,
}

// Trưởng phòng Kế toán
const CHIEF_ACCOUNTANT_ACCOUNT = {
  // XUAT BAO CAO SIM
  EXPORT_REPORT_SIM: PERMISSION.EXPORT_REPORT_SIM,

  // XUAT BAO CAO THE NAP
  EXPORT_CARD: PERMISSION.EXPORT_CARD,

  // XUAT BAO CAO GIA HAN
  EXPORT_RENEWALS: 'EXPORT_RENEWALS',

  // Quan ly diem thuong
  MANGAGE_BONUS: PERMISSION.MANGAGE_BONUS,
  VIEW_REQUEST_CHANGE_POINT: PERMISSION.VIEW_REQUEST_CHANGE_POINT, // Xem danh sách yêu cầu đổi điểm

  // sim gói
  MANAGE_SIM_WAREHOUSE_SIM_GOI: 'MANAGE_SIM_WAREHOUSE_SIM_GOI', // QUAN_LY_KHO_SIM (THẤY NAVIGATOR QUAN LY KHO SIM GÓI)
  VIEW_COMMISSION_RATE: PERMISSION.VIEW_COMMISSION_RATE, // Xem tỉ lệ hoa hông sim gói
  // REQUEST_ACTIVE_SIM_GOI: PERMISSION.REQUEST_ACTIVE_SIM_GOI, // Xem đơn yêu cầu kích hoạt sim gói
  FILTER_SIM_GOI: PERMISSION.FILTER_SIM_GOI,
  FILTER_BY_SELLER_SIM_PACKAGED: PERMISSION.FILTER_BY_SELLER_SIM_PACKAGED,
  VISIBLE_SIM_GOI: PERMISSION.VISIBLE_SIM_GOI,

  // sim số
  MANAGE_SIM_WAREHOUSE_SIM_SO: 'MANAGE_SIM_WAREHOUSE_SIM_SO', // QUAN_LY_KHO_SIM (THẤY NAVIGATOR QUAN LY KHO SIM SỐ)
  // REQUEST_ACTIVE_SIM_SO: PERMISSION.REQUEST_ACTIVE_SIM_SO, // Xem đơn yêu cầu kích hoạt sim gói
  FILTER_SIM_SO: PERMISSION.FILTER_SIM_SO,
  FILTER_BY_SELLER_SIM_SO: PERMISSION.FILTER_BY_SELLER_SIM_SO,
  VISIBLE_SIM_SO: PERMISSION.VISIBLE_SIM_SO,
  VIEW_EXTEND_ATTRACTIVE_SIM: 'VIEW_EXTEND_ATTRACTIVE_SIM', // XEM_DANH_SACH_GIA_HAN_GOI_CUOC_SIM_SO
  VIEW_ARCHIVED_EXTEND_ATTRACTIVE_SIM: 'VIEW_ARCHIVED_EXTEND_ATTRACTIVE_SIM', // XEM_DANH_SACH_GIA_HAN_GOI_CUOC_SIM_SO_DA_LUU_TRU

  // SAN PHAM
  CREATE_EXPORT_REQUEST: PERMISSION.CREATE_EXPORT_REQUEST,

  // DOI SOAT
  CROSS_CHECK: PERMISSION.CROSS_CHECK,

  ...STAFF,
  ...ACCOUNTANT_ACCOUNT,
}

// Nhân viên CSKH
const CUSTOMER_SERVICE_ACCOUNT = {
  VIEW_OWNER_SIM_GOI: PERMISSION.VIEW_OWNER_SIM_GOI, // XEM_CHU_SO_HUU_SIM_GOI
  VIEW_OWNER_SIM_SO: PERMISSION.VIEW_OWNER_SIM_SO, // XEM_CHU_SO_HUU_SIM_GOI
  VIEW_OWNER_SIM_M2M_DU_LICH: PERMISSION.VIEW_OWNER_SIM_M2M_DU_LICH, // XEM_CHU_SO_HUU_SIM_M2M_DU_LICH

  // QUAN_LY_TAI_KHOAN
  VIEW_NAV_ACCOUNT: PERMISSION.VIEW_NAV_ACCOUNT,
  VIEW_LIST_EXTERNAL_ACCOUNT: PERMISSION.VIEW_LIST_EXTERNAL_ACCOUNT,
  VIEW_LIST_PARTNER_ACCOUNT: PERMISSION.VIEW_LIST_PARTNER_ACCOUNT,
  VIEW_LIST_INTERNAL_ACCOUNT: PERMISSION.VIEW_LIST_INTERNAL_ACCOUNT,
  SEARCH_FILTER: PERMISSION.SEARCH_FILTER,
  VIEW_DETAIL_ACCOUNT: PERMISSION.VIEW_DETAIL_ACCOUNT,
  // LIST_ACCOUNT_INTERNAL_LOWER_MANAGER: PERMISSION.LIST_ACCOUNT_INTERNAL_LOWER_MANAGER,

  // QUAN_LY_TICKET
  VIEW_LIST_TICKET: PERMISSION.VIEW_LIST_TICKET,
  VIEW_DETAIL_TICKET: PERMISSION.VIEW_DETAIL_TICKET,
  UPDATE_STATUS_TICKET: PERMISSION.UPDATE_STATUS_TICKET,

  // DON_HANG
  VIEW_LIST_ORDER: PERMISSION.VIEW_LIST_ORDER,
  FILTER_SEARCH_ORDER: PERMISSION.FILTER_SEARCH_ORDER,
  VIEW_DETAIL_ORDER: PERMISSION.VIEW_DETAIL_ORDER,
  UPDATE_STATUS_ORDER: PERMISSION.UPDATE_STATUS_ORDER,

  VISIBLE_SIM_SO: PERMISSION.VISIBLE_SIM_SO,
  VISIBLE_SIM_GOI: PERMISSION.VISIBLE_SIM_GOI,
  VISIBLE_SIM_M2M: PERMISSION.VISIBLE_SIM_M2M,
  VISIBLE_SIM_M2M_DU_LICH: PERMISSION.VISIBLE_SIM_M2M_DU_LICH,

  MANAGE_SIM_ACTIVATION_SIM_GOI: PERMISSION.MANAGE_SIM_ACTIVATION_SIM_GOI,
  MANAGE_SIM_ACTIVATION_SIM_SO: PERMISSION.MANAGE_SIM_ACTIVATION_SIM_SO,
  MANAGE_SIM_ACTIVATION_M2M_DU_LICH: PERMISSION.MANAGE_SIM_ACTIVATION_M2M_DU_LICH,

  // GIA HAN GOI CUOC
  VIEW_EXTEND_ATTRACTIVE_SIM: PERMISSION.VIEW_EXTEND_ATTRACTIVE_SIM,
  VIEW_DETAIL_EXTEND_SIM: PERMISSION.VIEW_DETAIL_EXTEND_SIM,
  VIEW_ARCHIVED_EXTEND_ATTRACTIVE_SIM: PERMISSION.VIEW_ARCHIVED_EXTEND_ATTRACTIVE_SIM,
  VIEW_WARRANTY_MANAGEMENT_REQUEST: PERMISSION.VIEW_WARRANTY_MANAGEMENT_REQUEST, // XEM_DON_YEU_CAU_XUAT_NHAP_QUAN_LY_BAO_HANH
  VIEW_PRODUCT_PORTFOLIO: PERMISSION.VIEW_PRODUCT_PORTFOLIO,
  VIEW_LIST_PRODUCT: PERMISSION.VIEW_LIST_PRODUCT,
  WARRANTY_ACTIVATION: PERMISSION.WARRANTY_ACTIVATION, // KICH_HOAT_BAO_HANH
  VIEW_DETAIL_PRODUCT: PERMISSION.VIEW_DETAIL_PRODUCT,
  CREATE_EXPORT_REQUEST: PERMISSION.CREATE_EXPORT_REQUEST,
  // staff permission
  ...STAFF,
}

// Trưởng phòng kinh doanh
const BUSINESS_MANAGER = {
  CREATE_SETTING: PERMISSION.CREATE_SETTING,
  // QUAN_LY_GOI_CUOC
  CREATE_PACKAGED_SIM: PERMISSION.CREATE_PACKAGED_SIM,
  UPDATE_PACKAGED_SIM: PERMISSION.UPDATE_PACKAGED_SIM,
  DELETE_PACKAGED_SIM: PERMISSION.DELETE_PACKAGED_SIM,
  GET_LIST_PACKAGED_SIM: PERMISSION.GET_LIST_PACKAGED_SIM,
  GET_DETAIL_PACKAGED_SIM: PERMISSION.GET_DETAIL_PACKAGED_SIM,
  // SIM_GOI
  MANAGE_SIM_WAREHOUSE_SIM_GOI: PERMISSION.MANAGE_SIM_WAREHOUSE_SIM_GOI,
  EDIT_DETAIL_SIM_WAREHOUSE_SIM_GOI: PERMISSION.EDIT_DETAIL_SIM_WAREHOUSE_SIM_GOI,
  REMOVE_NUMBER_SIM_GOI: PERMISSION.REMOVE_NUMBER_SIM_GOI,
  RESTORE_LIST_SIM_GOI: PERMISSION.RESTORE_LIST_SIM_GOI,
  EDIT_BUTTON_DETAIL_SIM_GOI: PERMISSION.EDIT_BUTTON_DETAIL_SIM_GOI,
  REMOVE_BUTTON_DETAIL_SIM_GOI: PERMISSION.REMOVE_BUTTON_DETAIL_SIM_GOI,
  RESTORE_BUTTON_DETAIL_SIM_GOI: PERMISSION.RESTORE_BUTTON_DETAIL_SIM_GOI,
  FILTER_SIM_GOI: PERMISSION.FILTER_SIM_GOI,
  FILTER_BY_SELLER_SIM_PACKAGED: PERMISSION.FILTER_BY_SELLER_SIM_PACKAGED,
  VIEW_COMMISSION_RATE: PERMISSION.VIEW_COMMISSION_RATE,
  REQUEST_ACTIVE_SIM_GOI: PERMISSION.REQUEST_ACTIVE_SIM_GOI,
  FILTER_ACTIVE_SIM_GOI: PERMISSION.FILTER_ACTIVE_SIM_GOI,
  FILTER_ACTIVE_SIM_GOI_BY_CREATER: PERMISSION.FILTER_ACTIVE_SIM_GOI_BY_CREATER,
  MANAGE_SIM_ACTIVATION_SIM_GOI: PERMISSION.MANAGE_SIM_ACTIVATION_SIM_GOI,
  VISIBLE_SIM_GOI: PERMISSION.VISIBLE_SIM_GOI,
  VIEW_OWNER_SIM_GOI: PERMISSION.VIEW_OWNER_SIM_GOI,
  APPROVE_LIST_UPLOAD_SIM_GOI: PERMISSION.APPROVE_LIST_UPLOAD_SIM_GOI,
  UPDATE_LIST_UPLOAD_SIM_GOI: PERMISSION.UPDATE_LIST_UPLOAD_SIM_GOI,
  // SIM_SO
  MANAGE_SIM_WAREHOUSE_SIM_SO: PERMISSION.MANAGE_SIM_WAREHOUSE_SIM_SO,
  MANAGE_SIM_ACTIVATION_SIM_SO: PERMISSION.MANAGE_SIM_ACTIVATION_SIM_SO,
  EDIT_DETAIL_SIM_WAREHOUSE_SIM_SO: PERMISSION.EDIT_DETAIL_SIM_WAREHOUSE_SIM_SO,
  RESTORE_LIST_SIM_SO: PERMISSION.RESTORE_LIST_SIM_SO,
  REMOVE_NUMBER_SIM_SO: PERMISSION.REMOVE_NUMBER_SIM_SO,
  FILTER_SIM_SO: PERMISSION.FILTER_SIM_SO,
  FILTER_BY_SELLER_SIM_SO: PERMISSION.FILTER_BY_SELLER_SIM_SO,
  EDIT_BUTTON_DETAIL_SIM_SO: PERMISSION.EDIT_BUTTON_DETAIL_SIM_SO,
  REMOVE_BUTTON_DETAIL_SIM_SO: PERMISSION.REMOVE_BUTTON_DETAIL_SIM_SO,
  RESTORE_BUTTON_DETAIL_SIM_SO: PERMISSION.RESTORE_BUTTON_DETAIL_SIM_SO,
  VIEW_COMMISSION_RATE_SIM_SO: PERMISSION.VIEW_COMMISSION_RATE_SIM_SO,
  REQUEST_ACTIVE_SIM_SO: PERMISSION.REQUEST_ACTIVE_SIM_SO,
  FILTER_ACTIVE_SIM_SO: PERMISSION.FILTER_ACTIVE_SIM_SO,
  FILTER_ACTIVE_SIM_SO_BY_CREATER: PERMISSION.FILTER_ACTIVE_SIM_SO_BY_CREATER,
  VISIBLE_SIM_SO: PERMISSION.VISIBLE_SIM_SO,
  VIEW_OWNER_SIM_SO: PERMISSION.VIEW_OWNER_SIM_SO,
  APPROVE_LIST_UPLOAD_SIM_SO: PERMISSION.APPROVE_LIST_UPLOAD_SIM_SO,
  UPDATE_LIST_UPLOAD_SIM_SO: PERMISSION.UPDATE_LIST_UPLOAD_SIM_SO,
  // SIM_M2M_&_DU_LICH
  MANAGE_SIM_WAREHOUSE_M2M_DU_LICH: PERMISSION.MANAGE_SIM_WAREHOUSE_M2M_DU_LICH,
  MANAGE_SIM_ACTIVATION_M2M_DU_LICH: PERMISSION.MANAGE_SIM_ACTIVATION_M2M_DU_LICH,
  EDIT_DETAIL_SIM_WAREHOUSE_M2M_DU_LICH: PERMISSION.EDIT_DETAIL_SIM_WAREHOUSE_M2M_DU_LICH,
  RESTORE_LIST_SIM_M2M_DU_LICH: PERMISSION.RESTORE_LIST_SIM_M2M_DU_LICH,
  REMOVE_NUMBER_SIM_M2M_DU_LICH: PERMISSION.REMOVE_NUMBER_SIM_M2M_DU_LICH,
  FILTER_SIM_M2M_DU_LICH: PERMISSION.FILTER_SIM_M2M_DU_LICH,
  FILTER_BY_SELLER_SIM_M2M_DU_LICH: PERMISSION.FILTER_BY_SELLER_SIM_M2M_DU_LICH,
  EDIT_BUTTON_DETAIL_SIM_M2M_DU_LICH: PERMISSION.EDIT_BUTTON_DETAIL_SIM_M2M_DU_LICH,
  REMOVE_BUTTON_DETAIL_SIM_M2M_DU_LICH: PERMISSION.REMOVE_BUTTON_DETAIL_SIM_M2M_DU_LICH,
  RESTORE_BUTTON_DETAIL_SIM_M2M_DU_LICH: PERMISSION.RESTORE_BUTTON_DETAIL_SIM_M2M_DU_LICH,
  VIEW_COMMISSION_RATE_SIM_M2M_DU_LICH: PERMISSION.VIEW_COMMISSION_RATE_SIM_M2M_DU_LICH,
  REQUEST_ACTIVE_SIM_M2M_DU_LICH: PERMISSION.REQUEST_ACTIVE_SIM_M2M_DU_LICH,
  FILTER_ACTIVE_SIM_M2M_DU_LICH: PERMISSION.FILTER_ACTIVE_SIM_M2M_DU_LICH,
  VISIBLE_SIM_M2M_DU_LICH: PERMISSION.VISIBLE_SIM_M2M_DU_LICH,
  VISIBLE_SIM_M2M: PERMISSION.VISIBLE_SIM_M2M,
  VIEW_OWNER_SIM_M2M_DU_LICH: PERMISSION.VIEW_OWNER_SIM_M2M_DU_LICH,
  FILTER_ACTIVE_SIM_M2M_DU_LICH_BY_CREATER: PERMISSION.FILTER_ACTIVE_SIM_M2M_DU_LICH_BY_CREATER,
  FILTER_BY_SELLER_ACTIVE_SIM_M2M_DU_LICH: PERMISSION.FILTER_BY_SELLER_ACTIVE_SIM_M2M_DU_LICH,
  APPROVE_LIST_UPLOAD_M2M_DU_LICH: PERMISSION.APPROVE_LIST_UPLOAD_M2M_DU_LICH,
  UPDATE_LIST_UPLOAD_SIM_M2M_DU_LICH: PERMISSION.UPDATE_LIST_UPLOAD_SIM_M2M_DU_LICH,

  // QUAN_LY_WEBSITE
  VIEW_SETTING: PERMISSION.VIEW_SETTING,
  VIEW_SETTING_DL_M2M_CHINA: PERMISSION.VIEW_SETTING_DL_M2M_CHINA,

  // QUAN_LY_TAI_KHOAN
  VIEW_NAV_ACCOUNT: PERMISSION.VIEW_NAV_ACCOUNT,
  VIEW_LIST_EXTERNAL_ACCOUNT: PERMISSION.VIEW_LIST_EXTERNAL_ACCOUNT,
  VIEW_LIST_INTERNAL_ACCOUNT: PERMISSION.VIEW_LIST_INTERNAL_ACCOUNT,
  UPDATE_STATUS_ACCOUNT: PERMISSION.UPDATE_STATUS_ACCOUNT,
  SEARCH_FILTER: PERMISSION.SEARCH_FILTER,
  VIEW_DETAIL_ACCOUNT: PERMISSION.VIEW_DETAIL_ACCOUNT,
  // EDIT_DETAIL_ACCOUNT: PERMISSION.EDIT_DETAIL_ACCOUNT,

  // QUAN_LY_TICKET
  VIEW_LIST_TICKET: PERMISSION.VIEW_LIST_TICKET,
  VIEW_DETAIL_TICKET: PERMISSION.VIEW_DETAIL_TICKET,

  // UPDATE_STATUS_TICKET: PERMISSION.UPDATE_STATUS_TICKET,

  // DON_HANG
  VIEW_LIST_ORDER: PERMISSION.VIEW_LIST_ORDER,
  FILTER_SEARCH_ORDER: PERMISSION.FILTER_SEARCH_ORDER,
  VIEW_DETAIL_ORDER: PERMISSION.VIEW_DETAIL_ORDER,
  UPDATE_STATUS_ORDER: PERMISSION.UPDATE_STATUS_ORDER,

  // QUAN_LY_SAN_PHAM
  VIEW_PRODUCT_PORTFOLIO: PERMISSION.VIEW_PRODUCT_PORTFOLIO,
  VIEW_LIST_PRODUCT: PERMISSION.VIEW_LIST_PRODUCT,
  SEARCH_FILTER_PRODUCT: PERMISSION.SEARCH_FILTER_PRODUCT,
  WARRANTY_ACTIVATION: PERMISSION.WARRANTY_ACTIVATION,
  VIEW_DETAIL_PRODUCT: PERMISSION.VIEW_DETAIL_PRODUCT,
  CREATE_EXPORT_REQUEST: PERMISSION.CREATE_EXPORT_REQUEST,
  VIEW_IMPORT_REQUEST: PERMISSION.VIEW_IMPORT_REQUEST,

  // Xuất báo cáo
  // EXPORT_REPORT_SIM: PERMISSION.EXPORT_REPORT_SIM,

  // DOI SOAT
  CROSS_CHECK: PERMISSION.CROSS_CHECK,

  // staff permission
  ...STAFF,
}

// Nhân viên bán hàng
const SELLER_ACCOUNT = {
  // SIM_GOI
  MANAGE_SIM_WAREHOUSE_SIM_GOI: PERMISSION.MANAGE_SIM_WAREHOUSE_SIM_GOI,
  MANAGE_SIM_ACTIVATION_SIM_GOI: PERMISSION.MANAGE_SIM_ACTIVATION_SIM_GOI,
  REQUEST_ACTIVE_SIM_GOI: PERMISSION.REQUEST_ACTIVE_SIM_GOI,
  VISIBLE_SIM_GOI: PERMISSION.VISIBLE_SIM_GOI,
  FILTER_SIM_GOI: PERMISSION.FILTER_SIM_GOI,
  FILTER_ACTIVE_SIM_GOI: PERMISSION.FILTER_ACTIVE_SIM_GOI,

  // SIM_SO
  MANAGE_SIM_WAREHOUSE_SIM_SO: PERMISSION.MANAGE_SIM_WAREHOUSE_SIM_SO,
  MANAGE_SIM_ACTIVATION_SIM_SO: PERMISSION.MANAGE_SIM_ACTIVATION_SIM_SO,
  REQUEST_ACTIVE_SIM_SO: PERMISSION.REQUEST_ACTIVE_SIM_SO,
  VISIBLE_SIM_SO: PERMISSION.VISIBLE_SIM_SO,
  FILTER_SIM_SO: PERMISSION.FILTER_SIM_SO,
  FILTER_ACTIVE_SIM_SO: PERMISSION.FILTER_ACTIVE_SIM_SO,

  // SIM_M2M_&_DU_LICH
  MANAGE_SIM_WAREHOUSE_M2M_DU_LICH: PERMISSION.MANAGE_SIM_WAREHOUSE_M2M_DU_LICH,
  MANAGE_SIM_ACTIVATION_M2M_DU_LICH: PERMISSION.MANAGE_SIM_ACTIVATION_M2M_DU_LICH,
  REQUEST_ACTIVE_SIM_M2M_DU_LICH: PERMISSION.REQUEST_ACTIVE_SIM_M2M_DU_LICH,
  VISIBLE_SIM_M2M_DU_LICH: PERMISSION.VISIBLE_SIM_M2M_DU_LICH,
  FILTER_SIM_M2M_DU_LICH: PERMISSION.FILTER_SIM_M2M_DU_LICH,
  FILTER_ACTIVE_SIM_M2M_DU_LICH: PERMISSION.FILTER_ACTIVE_SIM_M2M_DU_LICH,

  // QUAN_LY_TAI_KHOAN
  VIEW_NAV_ACCOUNT: PERMISSION.VIEW_NAV_ACCOUNT,
  VIEW_LIST_INTERNAL_ACCOUNT: PERMISSION.VIEW_LIST_INTERNAL_ACCOUNT,
  SEARCH_FILTER: PERMISSION.SEARCH_FILTER,

  // DON_HANG
  VIEW_LIST_ORDER: PERMISSION.VIEW_LIST_ORDER,
  FILTER_SEARCH_ORDER: PERMISSION.FILTER_SEARCH_ORDER,
  VIEW_DETAIL_ORDER: PERMISSION.VIEW_DETAIL_ORDER,
  UPDATE_STATUS_ORDER: PERMISSION.UPDATE_STATUS_ORDER,

  // QUAN_LY_SAN_PHAM
  VIEW_PRODUCT_PORTFOLIO: PERMISSION.VIEW_PRODUCT_PORTFOLIO,
  VIEW_LIST_PRODUCT: PERMISSION.VIEW_LIST_PRODUCT,
  SEARCH_FILTER_PRODUCT: PERMISSION.SEARCH_FILTER_PRODUCT,
  WARRANTY_ACTIVATION: PERMISSION.WARRANTY_ACTIVATION,
  VIEW_DETAIL_PRODUCT: PERMISSION.VIEW_DETAIL_PRODUCT,
  CREATE_EXPORT_REQUEST: PERMISSION.CREATE_EXPORT_REQUEST,

  // staff permission
  ...STAFF,
}

// Quản lý Partner
const PARTNER_REPRESENTATIVE = {
  // SIM_GOI
  UPLOAD_LIST_SIM_GOI: PERMISSION.UPLOAD_LIST_SIM_GOI,
  APPROVE_LIST_UPLOAD_SIM_GOI: PERMISSION.APPROVE_LIST_UPLOAD_SIM_GOI,
  MANAGE_SIM_WAREHOUSE_SIM_GOI: PERMISSION.MANAGE_SIM_WAREHOUSE_SIM_GOI,
  MANAGE_SIM_ACTIVATION_SIM_GOI: PERMISSION.MANAGE_SIM_ACTIVATION_SIM_GOI,
  EDIT_DETAIL_SIM_WAREHOUSE_SIM_GOI: PERMISSION.EDIT_DETAIL_SIM_WAREHOUSE_SIM_GOI,
  RESTORE_LIST_SIM_GOI: PERMISSION.RESTORE_LIST_SIM_GOI,
  REMOVE_NUMBER_SIM_GOI: PERMISSION.REMOVE_NUMBER_SIM_GOI,
  EDIT_BUTTON_DETAIL_SIM_GOI: PERMISSION.EDIT_BUTTON_DETAIL_SIM_GOI,
  REMOVE_BUTTON_DETAIL_SIM_GOI: PERMISSION.REMOVE_BUTTON_DETAIL_SIM_GOI,
  RESTORE_BUTTON_DETAIL_SIM_GOI: PERMISSION.RESTORE_BUTTON_DETAIL_SIM_GOI,
  REQUEST_ACTIVE_SIM_GOI: PERMISSION.REQUEST_ACTIVE_SIM_GOI,
  FILTER_ACTIVE_SIM_GOI: PERMISSION.FILTER_ACTIVE_SIM_GOI,
  FILTER_ACTIVE_SIM_GOI_BY_CREATER: PERMISSION.FILTER_ACTIVE_SIM_GOI_BY_CREATER,
  VISIBLE_SIM_GOI: PERMISSION.VISIBLE_SIM_GOI,
  FILTER_SIM_GOI: PERMISSION.FILTER_SIM_GOI,
  REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE: PERMISSION.REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE,
  OTHER_DOCUMENT_UPLOAD: PERMISSION.OTHER_DOCUMENT_UPLOAD,
  // SIM_SO
  UPLOAD_LIST_SIM_SO: PERMISSION.UPLOAD_LIST_SIM_SO,
  APPROVE_LIST_UPLOAD_SIM_SO: PERMISSION.APPROVE_LIST_UPLOAD_SIM_SO,
  MANAGE_SIM_WAREHOUSE_SIM_SO: PERMISSION.MANAGE_SIM_WAREHOUSE_SIM_SO,
  MANAGE_SIM_ACTIVATION_SIM_SO: PERMISSION.MANAGE_SIM_ACTIVATION_SIM_SO,
  EDIT_DETAIL_SIM_WAREHOUSE_SIM_SO: PERMISSION.EDIT_DETAIL_SIM_WAREHOUSE_SIM_SO,
  RESTORE_LIST_SIM_SO: PERMISSION.RESTORE_LIST_SIM_SO,
  REMOVE_NUMBER_SIM_SO: PERMISSION.REMOVE_NUMBER_SIM_SO,
  EDIT_BUTTON_DETAIL_SIM_SO: PERMISSION.EDIT_BUTTON_DETAIL_SIM_SO,
  REMOVE_BUTTON_DETAIL_SIM_SO: PERMISSION.REMOVE_BUTTON_DETAIL_SIM_SO,
  RESTORE_BUTTON_DETAIL_SIM_SO: PERMISSION.RESTORE_BUTTON_DETAIL_SIM_SO,
  REQUEST_ACTIVE_SIM_SO: PERMISSION.REQUEST_ACTIVE_SIM_SO,
  FILTER_ACTIVE_SIM_SO: PERMISSION.FILTER_ACTIVE_SIM_SO,
  FILTER_ACTIVE_SIM_SO_BY_CREATER: PERMISSION.FILTER_ACTIVE_SIM_SO_BY_CREATER,
  VISIBLE_SIM_SO: PERMISSION.VISIBLE_SIM_SO,
  FILTER_SIM_SO: PERMISSION.FILTER_SIM_SO,
  REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE_SIM_SO: PERMISSION.REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE_SIM_SO,

  // SIM_M2M_&_DU_LICH
  UPLOAD_LIST_M2M_DU_LICH: PERMISSION.UPLOAD_LIST_M2M_DU_LICH,
  APPROVE_LIST_UPLOAD_M2M_DU_LICH: PERMISSION.APPROVE_LIST_UPLOAD_M2M_DU_LICH,
  MANAGE_SIM_WAREHOUSE_M2M_DU_LICH: PERMISSION.MANAGE_SIM_WAREHOUSE_M2M_DU_LICH,
  MANAGE_SIM_ACTIVATION_M2M_DU_LICH: PERMISSION.MANAGE_SIM_ACTIVATION_M2M_DU_LICH,
  EDIT_DETAIL_SIM_WAREHOUSE_M2M_DU_LICH: PERMISSION.EDIT_DETAIL_SIM_WAREHOUSE_M2M_DU_LICH,
  RESTORE_LIST_SIM_M2M_DU_LICH: PERMISSION.RESTORE_LIST_SIM_M2M_DU_LICH,
  REMOVE_NUMBER_SIM_M2M_DU_LICH: PERMISSION.REMOVE_NUMBER_SIM_M2M_DU_LICH,
  EDIT_BUTTON_DETAIL_SIM_M2M_DU_LICH: PERMISSION.EDIT_BUTTON_DETAIL_SIM_M2M_DU_LICH,
  REMOVE_BUTTON_DETAIL_SIM_M2M_DU_LICH: PERMISSION.REMOVE_BUTTON_DETAIL_SIM_M2M_DU_LICH,
  RESTORE_BUTTON_DETAIL_SIM_M2M_DU_LICH: PERMISSION.RESTORE_BUTTON_DETAIL_SIM_M2M_DU_LICH,
  REQUEST_ACTIVE_SIM_M2M_DU_LICH: PERMISSION.REQUEST_ACTIVE_SIM_M2M_DU_LICH,
  FILTER_ACTIVE_SIM_M2M_DU_LICH: PERMISSION.FILTER_ACTIVE_SIM_M2M_DU_LICH,
  VISIBLE_SIM_M2M_DU_LICH: PERMISSION.VISIBLE_SIM_M2M_DU_LICH,
  FILTER_ACTIVE_SIM_M2M_DU_LICH_BY_CREATER: PERMISSION.FILTER_ACTIVE_SIM_M2M_DU_LICH_BY_CREATER,
  FILTER_BY_SELLER_ACTIVE_SIM_M2M_DU_LICH: PERMISSION.FILTER_BY_SELLER_ACTIVE_SIM_M2M_DU_LICH,
  FILTER_SIM_M2M_DU_LICH: PERMISSION.FILTER_SIM_M2M_DU_LICH,
  REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE_SIM_M2M_DU_LICH: PERMISSION.REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE_SIM_M2M_DU_LICH,

  // QUAN_LY_TAI_KHOAN
  VIEW_NAV_ACCOUNT: PERMISSION.VIEW_NAV_ACCOUNT,
  VIEW_LIST_INTERNAL_ACCOUNT: PERMISSION.VIEW_LIST_INTERNAL_ACCOUNT,
  SEARCH_FILTER: PERMISSION.SEARCH_FILTER,
  VIEW_DETAIL_ACCOUNT: PERMISSION.VIEW_DETAIL_ACCOUNT,

  // staff permission
  ...STAFF,
}

const PARTNER_SELLER = {
  // SIM_GOI
  MANAGE_SIM_WAREHOUSE_SIM_GOI: PERMISSION.MANAGE_SIM_WAREHOUSE_SIM_GOI,
  MANAGE_SIM_ACTIVATION_SIM_GOI: PERMISSION.MANAGE_SIM_ACTIVATION_SIM_GOI,
  REQUEST_ACTIVE_SIM_GOI: PERMISSION.REQUEST_ACTIVE_SIM_GOI,
  VISIBLE_SIM_GOI: PERMISSION.VISIBLE_SIM_GOI,
  FILTER_SIM_GOI: PERMISSION.FILTER_SIM_GOI,
  REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE: PERMISSION.REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE,
  OTHER_DOCUMENT_UPLOAD: PERMISSION.OTHER_DOCUMENT_UPLOAD,

  // SIM_SO
  MANAGE_SIM_WAREHOUSE_SIM_SO: PERMISSION.MANAGE_SIM_WAREHOUSE_SIM_SO,
  MANAGE_SIM_ACTIVATION_SIM_SO: PERMISSION.MANAGE_SIM_ACTIVATION_SIM_SO,
  REQUEST_ACTIVE_SIM_SO: PERMISSION.REQUEST_ACTIVE_SIM_SO,
  VISIBLE_SIM_SO: PERMISSION.VISIBLE_SIM_SO,
  FILTER_SIM_SO: PERMISSION.FILTER_SIM_SO,
  FILTER_ACTIVE_SIM_SO: PERMISSION.FILTER_ACTIVE_SIM_SO,
  FILTER_ACTIVE_SIM_SO_BY_CREATER: PERMISSION.FILTER_ACTIVE_SIM_SO_BY_CREATER,
  REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE_SIM_SO: PERMISSION.REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE_SIM_SO,

  // SIM_M2M_&_DU_LICH
  MANAGE_SIM_WAREHOUSE_M2M_DU_LICH: PERMISSION.MANAGE_SIM_WAREHOUSE_M2M_DU_LICH,
  MANAGE_SIM_ACTIVATION_M2M_DU_LICH: PERMISSION.MANAGE_SIM_ACTIVATION_M2M_DU_LICH,
  REQUEST_ACTIVE_SIM_M2M_DU_LICH: PERMISSION.REQUEST_ACTIVE_SIM_M2M_DU_LICH,
  VISIBLE_SIM_M2M_DU_LICH: PERMISSION.VISIBLE_SIM_M2M_DU_LICH,
  FILTER_SIM_M2M_DU_LICH: PERMISSION.FILTER_SIM_M2M_DU_LICH,
  FILTER_ACTIVE_SIM_M2M_DU_LICH: PERMISSION.FILTER_ACTIVE_SIM_M2M_DU_LICH,
  REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE_SIM_M2M_DU_LICH: PERMISSION.REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE_SIM_M2M_DU_LICH,

  // staff permission
  ...STAFF,
}

// Nhân viên giao vận
const SHIPPER_ACCOUNT = {
  // DON_HANG
  VIEW_LIST_ORDER: PERMISSION.VIEW_LIST_ORDER,
  FILTER_SEARCH_ORDER: PERMISSION.FILTER_SEARCH_ORDER,
  VIEW_DETAIL_ORDER: PERMISSION.VIEW_DETAIL_ORDER,
  UPDATE_STATUS_ORDER: PERMISSION.UPDATE_STATUS_ORDER,

  // staff permission
  ...STAFF,
}

// Trưởng phòng kỹ thuật
const TECHNICAL_MANAGER = {
  // QUAN_LY_GOI_CUOC
  GET_LIST_PACKAGED_SIM: PERMISSION.GET_LIST_PACKAGED_SIM,
  // CREATE_PACKAGED_SIM: PERMISSION.CREATE_PACKAGED_SIM,
  // UPDATE_PACKAGED_SIM: PERMISSION.UPDATE_PACKAGED_SIM,
  // DELETE_PACKAGED_SIM: PERMISSION.DELETE_PACKAGED_SIM,
  GET_DETAIL_PACKAGED_SIM: PERMISSION.GET_DETAIL_PACKAGED_SIM,

  // SIM_M2M_&_DU_LICH
  // UPLOAD_LIST_M2M_DU_LICH: PERMISSION.UPLOAD_LIST_M2M_DU_LICH,
  // APPROVE_LIST_UPLOAD_M2M_DU_LICH: PERMISSION.APPROVE_LIST_UPLOAD_M2M_DU_LICH,
  UPDATE_LIST_UPLOAD_SIM_M2M_DU_LICH: PERMISSION.UPDATE_LIST_UPLOAD_SIM_M2M_DU_LICH,
  MANAGE_SIM_WAREHOUSE_M2M_DU_LICH: PERMISSION.MANAGE_SIM_WAREHOUSE_M2M_DU_LICH,
  MANAGE_SIM_ACTIVATION_M2M_DU_LICH: PERMISSION.MANAGE_SIM_ACTIVATION_M2M_DU_LICH,
  EDIT_DETAIL_SIM_WAREHOUSE_M2M_DU_LICH: PERMISSION.EDIT_DETAIL_SIM_WAREHOUSE_M2M_DU_LICH,
  RESTORE_LIST_SIM_M2M_DU_LICH: PERMISSION.RESTORE_LIST_SIM_M2M_DU_LICH,
  REMOVE_NUMBER_SIM_M2M_DU_LICH: PERMISSION.REMOVE_NUMBER_SIM_M2M_DU_LICH,
  FILTER_SIM_M2M_DU_LICH: PERMISSION.FILTER_SIM_M2M_DU_LICH,
  FILTER_BY_SELLER_SIM_M2M_DU_LICH: PERMISSION.FILTER_BY_SELLER_SIM_M2M_DU_LICH,
  EDIT_BUTTON_DETAIL_SIM_M2M_DU_LICH: PERMISSION.EDIT_BUTTON_DETAIL_SIM_M2M_DU_LICH,
  REMOVE_BUTTON_DETAIL_SIM_M2M_DU_LICH: PERMISSION.REMOVE_BUTTON_DETAIL_SIM_M2M_DU_LICH,
  RESTORE_BUTTON_DETAIL_SIM_M2M_DU_LICH: PERMISSION.RESTORE_BUTTON_DETAIL_SIM_M2M_DU_LICH,
  FILTER_ACTIVE_SIM_M2M_DU_LICH: PERMISSION.FILTER_ACTIVE_SIM_M2M_DU_LICH,
  ASSIGNEE_DETAIL_ACTIVE_SIM_M2M_DU_LICH: PERMISSION.ASSIGNEE_DETAIL_ACTIVE_SIM_M2M_DU_LICH,
  REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE_SIM_M2M_DU_LICH: PERMISSION.REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE_SIM_M2M_DU_LICH,
  VISIBLE_SIM_M2M_DU_LICH: PERMISSION.VISIBLE_SIM_M2M_DU_LICH,
  VISIBLE_SIM_M2M: PERMISSION.VISIBLE_SIM_M2M,
  FILTER_ACTIVE_SIM_M2M_DU_LICH_BY_CREATER: PERMISSION.FILTER_ACTIVE_SIM_M2M_DU_LICH_BY_CREATER,

  // SIM_GOI
  // UPLOAD_LIST_SIM_GOI: PERMISSION.UPLOAD_LIST_SIM_GOI,
  // APPROVE_LIST_UPLOAD_SIM_GOI: PERMISSION.APPROVE_LIST_UPLOAD_SIM_GOI,
  UPDATE_LIST_UPLOAD_SIM_GOI: PERMISSION.UPDATE_LIST_UPLOAD_SIM_GOI,

  MANAGE_SIM_ACTIVATION_SIM_GOI: PERMISSION.MANAGE_SIM_ACTIVATION_SIM_GOI,
  MANAGE_SIM_WAREHOUSE_SIM_GOI: PERMISSION.MANAGE_SIM_WAREHOUSE_SIM_GOI,
  RESTORE_LIST_SIM_GOI: PERMISSION.RESTORE_LIST_SIM_GOI,
  REMOVE_NUMBER_SIM_GOI: PERMISSION.REMOVE_NUMBER_SIM_GOI,
  FILTER_SIM_GOI: PERMISSION.FILTER_SIM_GOI,
  FILTER_BY_SELLER_SIM_PACKAGED: PERMISSION.FILTER_BY_SELLER_SIM_PACKAGED,
  EDIT_BUTTON_DETAIL_SIM_GOI: PERMISSION.EDIT_BUTTON_DETAIL_SIM_GOI,
  REMOVE_BUTTON_DETAIL_SIM_GOI: PERMISSION.REMOVE_BUTTON_DETAIL_SIM_GOI,
  RESTORE_BUTTON_DETAIL_SIM_GOI: PERMISSION.RESTORE_BUTTON_DETAIL_SIM_GOI,
  FILTER_ACTIVE_SIM_GOI: PERMISSION.FILTER_ACTIVE_SIM_GOI,
  FILTER_ACTIVE_SIM_GOI_BY_CREATER: PERMISSION.FILTER_ACTIVE_SIM_GOI_BY_CREATER,
  ASSIGNEE_DETAIL_ACTIVE_SIM_GOI: PERMISSION.ASSIGNEE_DETAIL_ACTIVE_SIM_GOI,
  REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE: PERMISSION.REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE,
  OTHER_DOCUMENT_UPLOAD: PERMISSION.OTHER_DOCUMENT_UPLOAD,
  VISIBLE_SIM_GOI: PERMISSION.VISIBLE_SIM_GOI,
  VIEW_COMMISSION_RATE: PERMISSION.VIEW_COMMISSION_RATE,
  EDIT_DETAIL_SIM_WAREHOUSE_SIM_GOI: PERMISSION.EDIT_DETAIL_SIM_WAREHOUSE_SIM_GOI,

  // SIM_SO
  // UPLOAD_LIST_SIM_SO: PERMISSION.UPLOAD_LIST_SIM_SO,
  // APPROVE_LIST_UPLOAD_SIM_SO: PERMISSION.APPROVE_LIST_UPLOAD_SIM_SO,
  MANAGE_SIM_WAREHOUSE_SIM_SO: PERMISSION.MANAGE_SIM_WAREHOUSE_SIM_SO,
  MANAGE_SIM_ACTIVATION_SIM_SO: PERMISSION.MANAGE_SIM_ACTIVATION_SIM_SO,
  EDIT_DETAIL_SIM_WAREHOUSE_SIM_SO: PERMISSION.EDIT_DETAIL_SIM_WAREHOUSE_SIM_SO,
  RESTORE_LIST_SIM_SO: PERMISSION.RESTORE_LIST_SIM_SO,
  REMOVE_NUMBER_SIM_SO: PERMISSION.REMOVE_NUMBER_SIM_SO,
  FILTER_SIM_SO: PERMISSION.FILTER_SIM_SO,
  FILTER_BY_SELLER_SIM_SO: PERMISSION.FILTER_BY_SELLER_SIM_SO,
  EDIT_BUTTON_DETAIL_SIM_SO: PERMISSION.EDIT_BUTTON_DETAIL_SIM_SO,
  REMOVE_BUTTON_DETAIL_SIM_SO: PERMISSION.REMOVE_BUTTON_DETAIL_SIM_SO,
  RESTORE_BUTTON_DETAIL_SIM_SO: PERMISSION.RESTORE_BUTTON_DETAIL_SIM_SO,
  FILTER_ACTIVE_SIM_SO: PERMISSION.FILTER_ACTIVE_SIM_SO,
  FILTER_ACTIVE_SIM_SO_BY_CREATER: PERMISSION.FILTER_ACTIVE_SIM_SO_BY_CREATER,
  ASSIGNEE_DETAIL_ACTIVE_SIM_SO: PERMISSION.ASSIGNEE_DETAIL_ACTIVE_SIM_SO,
  REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE_SIM_SO: PERMISSION.REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE_SIM_SO,
  VISIBLE_SIM_SO: PERMISSION.VISIBLE_SIM_SO,
  VIEW_COMMISSION_RATE_SIM_SO: PERMISSION.VIEW_COMMISSION_RATE_SIM_SO,

  // QUAN_LY_TAI_KHOAN
  VIEW_NAV_ACCOUNT: PERMISSION.VIEW_NAV_ACCOUNT,
  VIEW_LIST_PARTNER_ACCOUNT: PERMISSION.VIEW_LIST_PARTNER_ACCOUNT,
  VIEW_LIST_INTERNAL_ACCOUNT: PERMISSION.VIEW_LIST_INTERNAL_ACCOUNT,
  SEARCH_FILTER: PERMISSION.SEARCH_FILTER,
  VIEW_DETAIL_ACCOUNT: PERMISSION.VIEW_DETAIL_ACCOUNT,
  VIEW_DETAIL_PARTNER_ACCOUNT: PERMISSION.VIEW_DETAIL_PARTNER_ACCOUNT,

  // QUAN_LY_WEBSITE
  VIEW_SETTING: PERMISSION.VIEW_SETTING,
  VIEW_SETTING_DL_M2M_CHINA: PERMISSION.VIEW_SETTING_DL_M2M_CHINA,

  // QUAN_LY_TICKET
  VIEW_LIST_TICKET: PERMISSION.VIEW_LIST_TICKET,
  VIEW_DETAIL_TICKET: PERMISSION.VIEW_DETAIL_TICKET,
  UPDATE_STATUS_TICKET: PERMISSION.UPDATE_STATUS_TICKET,

  // DON_HANG
  VIEW_LIST_ORDER: PERMISSION.VIEW_LIST_ORDER,
  FILTER_SEARCH_ORDER: PERMISSION.FILTER_SEARCH_ORDER,
  VIEW_DETAIL_ORDER: PERMISSION.VIEW_DETAIL_ORDER,
  UPDATE_STATUS_ORDER: PERMISSION.UPDATE_STATUS_ORDER,

  // GIA HAN GOI CUOC
  VIEW_EXTEND_ATTRACTIVE_SIM: PERMISSION.VIEW_EXTEND_ATTRACTIVE_SIM,
  VIEW_DETAIL_EXTEND_SIM: PERMISSION.VIEW_DETAIL_EXTEND_SIM,
  CHANGE_TECHNICAL_ASSIGNMENT: PERMISSION.CHANGE_TECHNICAL_ASSIGNMENT,
  VIEW_ARCHIVED_EXTEND_ATTRACTIVE_SIM: PERMISSION.VIEW_ARCHIVED_EXTEND_ATTRACTIVE_SIM,

  // staff permission
  ...STAFF,
}

// Nhân viên kỹ thuật
const TECHNICAL_ACCOUNT = {
  // QUAN_LY_GOI_CUOC
  GET_LIST_PACKAGED_SIM: PERMISSION.GET_LIST_PACKAGED_SIM,
  GET_DETAIL_PACKAGED_SIM: PERMISSION.GET_DETAIL_PACKAGED_SIM,

  // SIM_GOI
  // UPLOAD_LIST_SIM_GOI: PERMISSION.UPLOAD_LIST_SIM_GOI,
  MANAGE_SIM_ACTIVATION_SIM_GOI: PERMISSION.MANAGE_SIM_ACTIVATION_SIM_GOI,
  FILTER_ACTIVE_SIM_GOI: PERMISSION.FILTER_ACTIVE_SIM_GOI,
  FILTER_ACTIVE_SIM_GOI_BY_CREATER: PERMISSION.FILTER_ACTIVE_SIM_GOI_BY_CREATER,
  REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE: PERMISSION.REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE,
  OTHER_DOCUMENT_UPLOAD: PERMISSION.OTHER_DOCUMENT_UPLOAD,
  VISIBLE_SIM_GOI: PERMISSION.VISIBLE_SIM_GOI,
  ASSIGNEE_DETAIL_ACTIVE_SIM_GOI: PERMISSION.ASSIGNEE_DETAIL_ACTIVE_SIM_GOI,

  // SIM_M2M_&_DU_LICH
  // UPLOAD_LIST_M2M_DU_LICH: PERMISSION.UPLOAD_LIST_M2M_DU_LICH,
  MANAGE_SIM_ACTIVATION_M2M_DU_LICH: PERMISSION.MANAGE_SIM_ACTIVATION_M2M_DU_LICH,
  FILTER_ACTIVE_SIM_M2M_DU_LICH: PERMISSION.FILTER_ACTIVE_SIM_M2M_DU_LICH,
  REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE_SIM_M2M_DU_LICH: PERMISSION.REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE_SIM_M2M_DU_LICH,
  VISIBLE_SIM_M2M_DU_LICH: PERMISSION.VISIBLE_SIM_M2M_DU_LICH,
  ASSIGNEE_DETAIL_ACTIVE_SIM_M2M_DU_LICH: PERMISSION.ASSIGNEE_DETAIL_ACTIVE_SIM_M2M_DU_LICH,
  VISIBLE_SIM_M2M: PERMISSION.VISIBLE_SIM_M2M,

  // SIM_SO
  // UPLOAD_LIST_SIM_SO: PERMISSION.UPLOAD_LIST_SIM_SO,
  MANAGE_SIM_ACTIVATION_SIM_SO: PERMISSION.MANAGE_SIM_ACTIVATION_SIM_SO,
  FILTER_ACTIVE_SIM_SO: PERMISSION.FILTER_ACTIVE_SIM_SO,
  FILTER_ACTIVE_SIM_SO_BY_CREATER: PERMISSION.FILTER_ACTIVE_SIM_SO_BY_CREATER,
  REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE_SIM_SO: PERMISSION.REJECT_ACCEPT_BUTTON_DETAIL_ACTIVE_SIM_SO,
  VISIBLE_SIM_SO: PERMISSION.VISIBLE_SIM_SO,
  ASSIGNEE_DETAIL_ACTIVE_SIM_SO: PERMISSION.ASSIGNEE_DETAIL_ACTIVE_SIM_SO,

  // QUAN_LY_TICKET
  VIEW_LIST_TICKET: PERMISSION.VIEW_LIST_TICKET,
  VIEW_DETAIL_TICKET: PERMISSION.VIEW_DETAIL_TICKET,
  // UPDATE_STATUS_TICKET: PERMISSION.UPDATE_STATUS_TICKET,

  // DON_HANG
  VIEW_LIST_ORDER: PERMISSION.VIEW_LIST_ORDER,
  FILTER_SEARCH_ORDER: PERMISSION.FILTER_SEARCH_ORDER,
  VIEW_DETAIL_ORDER: PERMISSION.VIEW_DETAIL_ORDER,
  UPDATE_STATUS_ORDER: PERMISSION.UPDATE_STATUS_ORDER,

  // GIA HAN GOI CUOC
  VIEW_EXTEND_ATTRACTIVE_SIM: PERMISSION.VIEW_EXTEND_ATTRACTIVE_SIM,
  VIEW_DETAIL_EXTEND_SIM: PERMISSION.VIEW_DETAIL_EXTEND_SIM,
  // VIEW_ARCHIVED_EXTEND_ATTRACTIVE_SIM: PERMISSION.VIEW_ARCHIVED_EXTEND_ATTRACTIVE_SIM,

  // staff permission
  ...STAFF,
}

// Phó giám đốc tài chính
const FINANCE_ADMIN = {
  // // SIM_M2M_&_DU_LICH
  // UPLOAD_LIST_M2M_DU_LICH: PERMISSION.UPLOAD_LIST_M2M_DU_LICH,
  // APPROVE_LIST_UPLOAD_M2M_DU_LICH: PERMISSION.APPROVE_LIST_UPLOAD_M2M_DU_LICH,
  // MANAGE_SIM_WAREHOUSE_M2M_DU_LICH: PERMISSION.MANAGE_SIM_WAREHOUSE_M2M_DU_LICH,
  // MANAGE_SIM_ACTIVATION_M2M_DU_LICH: PERMISSION.MANAGE_SIM_ACTIVATION_M2M_DU_LICH,
  // EDIT_DETAIL_SIM_WAREHOUSE_M2M_DU_LICH: PERMISSION.EDIT_DETAIL_SIM_WAREHOUSE_M2M_DU_LICH,

  // // SIM_GOI
  // UPLOAD_LIST_SIM_GOI: PERMISSION.UPLOAD_LIST_SIM_GOI,
  // APPROVE_LIST_UPLOAD_SIM_GOI: PERMISSION.APPROVE_LIST_UPLOAD_SIM_GOI,
  // MANAGE_SIM_WAREHOUSE_SIM_GOI: PERMISSION.MANAGE_SIM_WAREHOUSE_SIM_GOI,
  // MANAGE_SIM_ACTIVATION_SIM_GOI: PERMISSION.MANAGE_SIM_ACTIVATION_SIM_GOI,
  // EDIT_DETAIL_SIM_WAREHOUSE_SIM_GOI: PERMISSION.EDIT_DETAIL_SIM_WAREHOUSE_SIM_GOI,

  // // SIM_SO
  // UPLOAD_LIST_SIM_SO: PERMISSION.UPLOAD_LIST_SIM_SO,
  // APPROVE_LIST_UPLOAD_SIM_SO: PERMISSION.APPROVE_LIST_UPLOAD_SIM_SO,
  // MANAGE_SIM_WAREHOUSE_SIM_SO: PERMISSION.MANAGE_SIM_WAREHOUSE_SIM_SO,
  // MANAGE_SIM_ACTIVATION_SIM_SO: PERMISSION.MANAGE_SIM_ACTIVATION_SIM_SO,
  // EDIT_DETAIL_SIM_WAREHOUSE_SIM_SO: PERMISSION.EDIT_DETAIL_SIM_WAREHOUSE_SIM_SO,

  // admin permission
  ...ADMIN,
}

// Trưởng phòng cskh
const CUSTOMER_SERVICE_MANAGER = {
  VIEW_OWNER_SIM_GOI: PERMISSION.VIEW_OWNER_SIM_GOI, // XEM_CHU_SO_HUU_SIM_GOI
  VIEW_OWNER_SIM_SO: PERMISSION.VIEW_OWNER_SIM_SO, // XEM_CHU_SO_HUU_SIM_GOI

  // QUAN_LY_TAI_KHOAN
  VIEW_NAV_ACCOUNT: PERMISSION.VIEW_NAV_ACCOUNT,
  VIEW_LIST_INTERNAL_ACCOUNT: PERMISSION.VIEW_LIST_INTERNAL_ACCOUNT,
  SEARCH_FILTER: PERMISSION.SEARCH_FILTER,
  // LIST_ACCOUNT_INTERNAL_LOWER_MANAGER: PERMISSION.LIST_ACCOUNT_INTERNAL_LOWER_MANAGER,

  // QUAN_LY_TICKET
  VIEW_LIST_TICKET: PERMISSION.VIEW_LIST_TICKET,
  VIEW_DETAIL_TICKET: PERMISSION.VIEW_DETAIL_TICKET,
  UPDATE_STATUS_TICKET: PERMISSION.UPDATE_STATUS_TICKET,

  // DON_HANG
  VIEW_LIST_ORDER: PERMISSION.VIEW_LIST_ORDER,
  FILTER_SEARCH_ORDER: PERMISSION.FILTER_SEARCH_ORDER,
  VIEW_DETAIL_ORDER: PERMISSION.VIEW_DETAIL_ORDER,
  UPDATE_STATUS_ORDER: PERMISSION.UPDATE_STATUS_ORDER,

  // GIA HAN GOI CUOC
  VIEW_EXTEND_ATTRACTIVE_SIM: PERMISSION.VIEW_EXTEND_ATTRACTIVE_SIM,
  VISIBLE_SIM_SO: PERMISSION.VISIBLE_SIM_SO, // MỞ lên để xem được ticket gia hạn gói cước sim số
  VISIBLE_SIM_GOI: PERMISSION.VISIBLE_SIM_GOI,
  VISIBLE_SIM_M2M: PERMISSION.VISIBLE_SIM_M2M,
  CHANGE_CSKH_ASSIGNMENT: PERMISSION.CHANGE_CSKH_ASSIGNMENT, // Thay đổi phân công CSKH
  VIEW_ARCHIVED_EXTEND_ATTRACTIVE_SIM: PERMISSION.VIEW_ARCHIVED_EXTEND_ATTRACTIVE_SIM,

  // SAN PHAM
  CREATE_EXPORT_REQUEST: PERMISSION.CREATE_EXPORT_REQUEST,

  VIEW_WARRANTY_MANAGEMENT_REQUEST: PERMISSION.VIEW_WARRANTY_MANAGEMENT_REQUEST,

  VIEW_PRODUCT_PORTFOLIO: PERMISSION.VIEW_PRODUCT_PORTFOLIO,

  VIEW_LIST_PRODUCT: PERMISSION.VIEW_LIST_PRODUCT,

  WARRANTY_ACTIVATION: PERMISSION.WARRANTY_ACTIVATION, // KICH_HOAT_BAO_HANH
  VIEW_DETAIL_PRODUCT: PERMISSION.VIEW_DETAIL_PRODUCT,

  VIEW_DETAIL_EXTEND_SIM: PERMISSION.VIEW_DETAIL_EXTEND_SIM,

  VISIBLE_SIM_M2M_DU_LICH: PERMISSION.VISIBLE_SIM_M2M_DU_LICH,
  MANAGE_SIM_ACTIVATION_SIM_GOI: PERMISSION.MANAGE_SIM_ACTIVATION_SIM_GOI,
  MANAGE_SIM_ACTIVATION_SIM_SO: PERMISSION.MANAGE_SIM_ACTIVATION_SIM_SO,
  MANAGE_SIM_ACTIVATION_M2M_DU_LICH: PERMISSION.MANAGE_SIM_ACTIVATION_M2M_DU_LICH,
  VIEW_OWNER_SIM_M2M_DU_LICH: PERMISSION.VIEW_OWNER_SIM_M2M_DU_LICH,
}

// Trưởng phòng marketing
const MARKETTING_MANAGER = {
  CREATE_SETTING: PERMISSION.CREATE_SETTING,
  // QUAN_LY_WEBSITE
  VIEW_SETTING: PERMISSION.VIEW_SETTING,

  // QUAN_LY_TICKET
  VIEW_LIST_TICKET: PERMISSION.VIEW_LIST_TICKET,
  VIEW_DETAIL_TICKET: PERMISSION.VIEW_DETAIL_TICKET,

  // BLOG
  VIEW_NAVIGATE_BLOG: PERMISSION.VIEW_NAVIGATE_BLOG, // THẤY NAVIGATION BLOG
  VIEW_LIST_BLOG: PERMISSION.VIEW_LIST_BLOG, // XEM_DANH_SACH_BLOG
  ACTION_LIST_BLOG: PERMISSION.ACTION_LIST_BLOG, // THAO TAC TREN TRANG DANH SACH
  ACTION_DETAIL_BLOG: PERMISSION.ACTION_DETAIL_BLOG, // THAO TAC TREN TRANG CHI TIET
  EDIT_BLOG_OTHER_USER: PERMISSION.EDIT_BLOG_OTHER_USER, // SUA BLOG NGUOI KHAC
  EDIT_BLOG_OWN: PERMISSION.EDIT_BLOG_OWN, // SUA BLOG CUA MINH
  DELETE_BLOG_OTHER_USER: PERMISSION.DELETE_BLOG_OTHER_USER, // XOA BLOG NGUOI KHAC
  DELETE_BLOG_OWN: PERMISSION.DELETE_BLOG_OWN, // XOA BLOG CUA MINH
}

// Nhân viên marketing
const MARKETTING_ACCOUNT = {
  // QUAN_LY_WEBSITE
  CREATE_SETTING: PERMISSION.CREATE_SETTING,
  VIEW_SETTING: PERMISSION.VIEW_SETTING,
  EDIT_BLOG_OWN: PERMISSION.EDIT_BLOG_OWN, // SUA BLOG CUA MINH

  // BLOG
  VIEW_NAVIGATE_BLOG: PERMISSION.VIEW_NAVIGATE_BLOG, // THẤY NAVIGATION BLOG
  VIEW_LIST_BLOG: PERMISSION.VIEW_LIST_BLOG, // XEM_DANH_SACH_BLOG
  DELETE_BLOG_OWN: PERMISSION.DELETE_BLOG_OWN, // XOA BLOG CUA MINH
}

const EXTERNAL_ACCOUNT = {
  // SIM_GOI
  MANAGE_SIM_WAREHOUSE_SIM_GOI: PERMISSION.MANAGE_SIM_WAREHOUSE_SIM_GOI,
  MANAGE_SIM_ACTIVATION_SIM_GOI: PERMISSION.MANAGE_SIM_ACTIVATION_SIM_GOI,
  REQUEST_ACTIVE_SIM_GOI: PERMISSION.REQUEST_ACTIVE_SIM_GOI,
  VISIBLE_SIM_GOI: PERMISSION.VISIBLE_SIM_GOI,
  FILTER_ACTIVE_SIM_GOI: PERMISSION.FILTER_ACTIVE_SIM_GOI,
  FILTER_SIM_GOI: PERMISSION.FILTER_SIM_GOI,
  // SIM_SO,
  MANAGE_SIM_WAREHOUSE_SIM_SO: PERMISSION.MANAGE_SIM_WAREHOUSE_SIM_SO,
  MANAGE_SIM_ACTIVATION_SIM_SO: PERMISSION.MANAGE_SIM_ACTIVATION_SIM_SO,
  REQUEST_ACTIVE_SIM_SO: PERMISSION.REQUEST_ACTIVE_SIM_SO,
  VISIBLE_SIM_SO: PERMISSION.VISIBLE_SIM_SO,
  FILTER_ACTIVE_SIM_SO: PERMISSION.FILTER_ACTIVE_SIM_SO,
  FILTER_SIM_SO: PERMISSION.FILTER_SIM_SO,
  UPLOAD_LIST_SIM_SO: PERMISSION.UPLOAD_LIST_SIM_SO,
  APPROVE_LIST_UPLOAD_SIM_SO: PERMISSION.APPROVE_LIST_UPLOAD_SIM_SO,
  // SIM_M2M_&_DU_LICH
  MANAGE_SIM_WAREHOUSE_M2M_DU_LICH: PERMISSION.MANAGE_SIM_WAREHOUSE_M2M_DU_LICH,
  MANAGE_SIM_ACTIVATION_M2M_DU_LICH: PERMISSION.MANAGE_SIM_ACTIVATION_M2M_DU_LICH,
  REQUEST_ACTIVE_SIM_M2M_DU_LICH: PERMISSION.REQUEST_ACTIVE_SIM_M2M_DU_LICH,
  VISIBLE_SIM_M2M_DU_LICH: PERMISSION.VISIBLE_SIM_M2M_DU_LICH,
  FILTER_SIM_M2M_DU_LICH: PERMISSION.FILTER_SIM_M2M_DU_LICH,

  // QUAN_LY_SAN_PHAM
  VIEW_LIST_PRODUCT: PERMISSION.VIEW_LIST_PRODUCT,
  SEARCH_FILTER_PRODUCT: PERMISSION.SEARCH_FILTER_PRODUCT,
  WARRANTY_ACTIVATION: PERMISSION.WARRANTY_ACTIVATION,
  FILTER_ACTIVE_SIM_M2M_DU_LICH: PERMISSION.FILTER_ACTIVE_SIM_M2M_DU_LICH,
  // FILTER_ACTIVE_SIM_M2M_DU_LICH_BY_CREATER: PERMISSION.FILTER_ACTIVE_SIM_M2M_DU_LICH_BY_CREATER,
  // staff permission
  OTHER_DOCUMENT_UPLOAD: PERMISSION.OTHER_DOCUMENT_UPLOAD,

  // Đơn hàng
  VIEW_LIST_ORDER: PERMISSION.VIEW_LIST_ORDER,
  TOPUP_ORDERS_MANAGEMENT: PERMISSION.TOPUP_ORDERS_MANAGEMENT,
  EXPORT_CARD: PERMISSION.EXPORT_CARD,
  ...STAFF,
}

// Đại lý
const AGENT_EXTERNAL_ACCOUNT = {
  ...EXTERNAL_ACCOUNT,
}

// Cộng tác viên
const COLLABORATOR_EXTERNAL_ACCOUNT = {
  ...EXTERNAL_ACCOUNT,
}

// Khách hàng bán lẻ
const RETAIL_CUSTOMER_EXTERNAL_ACCOUNT = {
  ...EXTERNAL_ACCOUNT,
}

// Khách hàng doanh nghiệp
const BUSINESS_CUSTOMER_EXTERNAL_ACCOUNT = {
  ...EXTERNAL_ACCOUNT,
  RESTORE_LIST_SIM_SO: PERMISSION.RESTORE_LIST_SIM_SO,
  REMOVE_NUMBER_SIM_SO: PERMISSION.REMOVE_NUMBER_SIM_SO,
  RESTORE_BUTTON_DETAIL_SIM_SO: PERMISSION.RESTORE_BUTTON_DETAIL_SIM_SO,
  REMOVE_BUTTON_DETAIL_SIM_SO: PERMISSION.REMOVE_BUTTON_DETAIL_SIM_SO,
}

export const PERMISSION_ROLE = [
  {
    role: ROLE.SUPER_ADMIN,
    permission: PERMISSION,
  },
  // PHONG_DIEU_HANH
  {
    role: ROLE.MANAGING_ADMIN,
    permission: MANAGING_ADMIN,
  },
  {
    role: ROLE.ADMINISTRATIVE_MANAGER,
    permission: ADMINISTRATIVE_MANAGER,
  },
  {
    role: ROLE.ADMINISTRATIVE_ACCOUNT,
    permission: ADMINISTRATIVE_ACCOUNT,
  },
  {
    role: ROLE.WAREHOUSE_ACCOUNT,
    permission: WAREHOUSE_ACCOUNT,
  },
  {
    role: ROLE.ACCOUNTANT_ACCOUNT,
    permission: ACCOUNTANT_ACCOUNT,
  },
  {
    role: ROLE.CHIEF_ACCOUNTANT_ACCOUNT,
    permission: CHIEF_ACCOUNTANT_ACCOUNT,
  },
  {
    role: ROLE.CUSTOMER_SERVICE_ACCOUNT,
    permission: CUSTOMER_SERVICE_ACCOUNT,
  },
  {
    role: ROLE.ADDITIONAL_PROFILE_ACCOUNT,
    permission: ADDITIONAL_PROFILE_ACCOUNT,
  },
  // PHONG_KINH_DOANH
  {
    role: ROLE.BUSINESS_MANAGER,
    permission: BUSINESS_MANAGER,
  },
  {
    role: ROLE.SELLER_ACCOUNT,
    permission: SELLER_ACCOUNT,
  },
  {
    role: ROLE.PARTNER_REPRESENTATIVE,
    permission: PARTNER_REPRESENTATIVE,
  },
  {
    role: ROLE.PARTNER_SELLER,
    permission: PARTNER_SELLER,
  },
  {
    role: ROLE.SHIPPER_ACCOUNT,
    permission: SHIPPER_ACCOUNT,
  },
  {
    role: ROLE.EXTERNAL_ACCOUNT,
    permission: EXTERNAL_ACCOUNT,
  },
  {
    role: ROLE.AGENT_EXTERNAL_ACCOUNT,
    permission: AGENT_EXTERNAL_ACCOUNT,
  },
  {
    role: ROLE.BUSINESS_CUSTOMER_EXTERNAL_ACCOUNT,
    permission: BUSINESS_CUSTOMER_EXTERNAL_ACCOUNT,
  },
  {
    role: ROLE.COLLABORATOR_EXTERNAL_ACCOUNT,
    permission: COLLABORATOR_EXTERNAL_ACCOUNT,
  },
  {
    role: ROLE.RETAIL_CUSTOMER_EXTERNAL_ACCOUNT,
    permission: RETAIL_CUSTOMER_EXTERNAL_ACCOUNT,
  },
  // PHONG_KY_THUAT_NGHIEP_VU
  {
    role: ROLE.TECHNICAL_MANAGER,
    permission: TECHNICAL_MANAGER,
  },
  {
    role: ROLE.TECHNICAL_ACCOUNT,
    permission: TECHNICAL_ACCOUNT,
  },
  {
    role: ROLE.FINANCE_ADMIN,
    permission: FINANCE_ADMIN,
  },
  {
    role: ROLE.CUSTOMER_SERVICE_MANAGER,
    permission: CUSTOMER_SERVICE_MANAGER,
  },
  {
    role: ROLE.MARKETTING_MANAGER,
    permission: MARKETTING_MANAGER,
  },
  {
    role: ROLE.MARKETTING_ACCOUNT,
    permission: MARKETTING_ACCOUNT,
  },
]
