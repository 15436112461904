/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { url as navsURL } from '../../constants/navs.js'
import { enableScroll, isMobileDevice } from '../../util'
import { handlePermission, isHasPermission } from '../../redux/reducers/permission'
import { PERMISSION } from '../../constants/permission'
import { get, KEYS } from '../../util/localStorage'

function NavItem({ item, currentPath, setIsShowSideBar }) {
  const userPermission = handlePermission(get(KEYS.MY_ROLES))

  const [isExpanded, setIsExpanded] = useState(false)
  const [permissionRole, setPermissionRole] = useState(null)

  const handleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const handleClickNavItem = (e) => {
    if (userPermission?.[0]?.role === 'partner_representative' && item.url === '/quan-ly-tai-khoan' && isMobileDevice()) {
      window.location = '/quan-ly-tai-khoan'
      return
    }
    setPermissionRole(handlePermission(get(KEYS.MY_ROLES) || []))
    if (isMobileDevice()) {
      if (!item?.children) {
        setIsShowSideBar(false)
        enableScroll()
      }
      if (item.children && item.children.length > 0 && isMobileDevice()) {
        e.preventDefault()
      }
    }
    setIsExpanded(!isExpanded)
  }

  const handleClickNavChildItem = () => {
    if (isMobileDevice()) {
      setIsShowSideBar(false)
      enableScroll()
    }
  }
  const isActiveParent = currentPath.includes(item.url)

  const isPermission = (url, path) => {
    if (url === navsURL.sim_package) {
      switch (path) {
        case navsURL.upload_sim_package:
          return isHasPermission(
            PERMISSION.UPLOAD_LIST_SIM_GOI,
            permissionRole,
          )
        case navsURL.active_sim_package:
          return isHasPermission(
            PERMISSION.MANAGE_SIM_ACTIVATION_SIM_GOI,
            permissionRole,
          )
        case navsURL.sim_package_management:
          return isHasPermission(
            PERMISSION.MANAGE_SIM_WAREHOUSE_SIM_GOI,
            permissionRole,
          )
        case navsURL.approve_list_upload_sim_goi:
          return isHasPermission(
            PERMISSION.APPROVE_LIST_UPLOAD_SIM_GOI,
            permissionRole,
          )
        case navsURL.extend_sim_package:
          return isHasPermission(
            PERMISSION.VIEW_EXTEND_ATTRACTIVE_SIM,
            permissionRole,
          )
        default:
          return true
      }
    } else if (url === navsURL.sim_number) {
      switch (path) {
        case navsURL.upload_sim_number:
          return isHasPermission(PERMISSION.UPLOAD_LIST_SIM_SO, permissionRole)
        case navsURL.active_sim_number:
          return isHasPermission(
            PERMISSION.MANAGE_SIM_ACTIVATION_SIM_SO,
            permissionRole,
          )
        case navsURL.sim_number_management:
          return isHasPermission(
            PERMISSION.MANAGE_SIM_WAREHOUSE_SIM_SO,
            permissionRole,
          )
        case navsURL.approve_list_upload_sim_number:
          return isHasPermission(
            PERMISSION.APPROVE_LIST_UPLOAD_SIM_SO,
            permissionRole,
          )
        case navsURL.extend_sim_number:
          return isHasPermission(
            PERMISSION.VIEW_EXTEND_ATTRACTIVE_SIM,
            permissionRole,
          )
        default:
          return true
      }
    } else if (url === navsURL.sim_travel) {
      switch (path) {
        case navsURL.upload_sim_travel:
          return isHasPermission(
            PERMISSION.UPLOAD_LIST_M2M_DU_LICH,
            permissionRole,
          )
        case navsURL.active_sim_travel:
          return isHasPermission(
            PERMISSION.MANAGE_SIM_ACTIVATION_M2M_DU_LICH,
            permissionRole,
          )
        case navsURL.sim_travel_management:
          return isHasPermission(
            PERMISSION.MANAGE_SIM_WAREHOUSE_M2M_DU_LICH,
            permissionRole,
          )
        case navsURL.approve_list_upload_sim_travel:
          return isHasPermission(
            PERMISSION.APPROVE_LIST_UPLOAD_M2M_DU_LICH,
            permissionRole,
          )
        default:
          return true
      }
    } else if (url === navsURL.sim_m2m) {
      switch (path) {
        case navsURL.upload_sim_m2m:
          return isHasPermission(
            PERMISSION.UPLOAD_LIST_M2M_DU_LICH,
            permissionRole,
          )
        case navsURL.active_sim_m2m:
          return isHasPermission(
            PERMISSION.MANAGE_SIM_ACTIVATION_M2M_DU_LICH,
            permissionRole,
          )
        case navsURL.sim_m2m_management:
          return isHasPermission(
            PERMISSION.MANAGE_SIM_WAREHOUSE_M2M_DU_LICH,
            permissionRole,
          )
        case navsURL.approve_list_upload_sim_m2m:
          return isHasPermission(
            PERMISSION.APPROVE_LIST_UPLOAD_M2M_DU_LICH,
            permissionRole,
          )
        case navsURL.extend_sim_m2m:
          return isHasPermission(
            PERMISSION.VIEW_EXTEND_ATTRACTIVE_SIM,
            permissionRole,
          )
        default:
          return true
      }
    } else if (url === navsURL.product_management) {
      switch (path) {
        case navsURL.product_portfolio:
          return isHasPermission(
            PERMISSION.VIEW_PRODUCT_PORTFOLIO,
            permissionRole,
          )
        case navsURL.product:
          return isHasPermission(
            PERMISSION.VIEW_LIST_PRODUCT,
            permissionRole,
          )
        case navsURL.product_inventory:
          return isHasPermission(
            [PERMISSION.CREATE_EXPORT_REQUEST, PERMISSION.CREATE_IMPORT_REQUEST],
            permissionRole,
          )
        case navsURL.product_warranty_management:
          return isHasPermission(
            PERMISSION.CREATE_IMPORT_EXPORT_WARRANTY_MANAGEMENT_REQUEST,
            permissionRole,
          )
        default:
          return true
      }
    } else if (url === navsURL.setting_management) {
      switch (path) {
        case navsURL.discount_card_management:
          return isHasPermission(
            PERMISSION.DISCOUNT_CARD_MANAGEMENT,
            permissionRole,
          )
        default:
          return true
      }
    } else if (url === navsURL.account_management) {
      switch (path) {
        case navsURL.internal_account:
          return isHasPermission(
            PERMISSION.VIEW_LIST_INTERNAL_ACCOUNT,
            permissionRole,
          )
        // case navsURL.external_account:
        //   return isHasPermission(
        //     PERMISSION.VIEW_LIST_EXTERNAL_ACCOUNT,
        //     permissionRole,
        //   )
        case navsURL.doanhnghiep_account:
          return isHasPermission(
            PERMISSION.VIEW_LIST_EXTERNAL_ACCOUNT,
            permissionRole,
          )
        case navsURL.ctv_account:
          return isHasPermission(
            PERMISSION.VIEW_LIST_EXTERNAL_ACCOUNT,
            permissionRole,
          )
        case navsURL.partner_account:
          return isHasPermission(
            PERMISSION.VIEW_LIST_PARTNER_ACCOUNT,
            permissionRole,
          )
        default:
          return true
      }
    } else if (url === navsURL.website_manage) {
      switch (path) {
        case navsURL.website_customer:
          return isHasPermission(PERMISSION.VIEW_SETTING, userPermission)
        case navsURL.website_dl:
          return isHasPermission(PERMISSION.VIEW_SETTING_DL_M2M_CHINA, userPermission)
        case navsURL.website_m2m:
          return isHasPermission(PERMISSION.VIEW_SETTING_DL_M2M_CHINA, userPermission)
        case navsURL.website_china:
          return isHasPermission(PERMISSION.VIEW_SETTING_DL_M2M_CHINA, userPermission)
        case navsURL.website_taiwan:
          return isHasPermission(PERMISSION.VIEW_SETTING_DL_M2M_CHINA, userPermission)
        default:
          return true
      }
    } else if (url === navsURL.order_management) {
      switch (path) {
        case navsURL.sim_order_management:
          return isHasPermission(PERMISSION.PROVIDER_ORDERS_MANAGEMENT, userPermission)
        case navsURL.topup_order_management:
          return isHasPermission(PERMISSION.TOPUP_ORDERS_MANAGEMENT, userPermission)
        case navsURL.provider_order_management:
          return isHasPermission(PERMISSION.PROVIDER_ORDERS_MANAGEMENT, userPermission)
        default:
          return true
      }
    }

    return true
  }

  return (
    userPermission?.[0]?.role === 'partner_representative' && item.url === '/quan-ly-tai-khoan'
      ? (
        <div className="nav-item">
          <Link
            to={item.url}
            className={`nav-left ${isActiveParent ? 'active' : ''}`}
            role="presentation"
            onClick={handleClickNavItem}
          >
            <div className="d-flex align-items-center">
              {item.icon && (
                <img
                  className="nav-icon"
                  src={`/images/nav-icon/${item.icon}`}
                  alt={item.name}
                />
              )}
              {item.name}
            </div>
          </Link>

        </div>
      )
      : (
        <div className="nav-item">
          {item.children && item.children.length > 0 ? (
            <div
              to={
            item.children && item.children.length > 0
              ? item.children[0].url
              : item.url
          }
              className={`nav-left ${isActiveParent ? 'active' : ''}`}
              role="presentation"
              onClick={handleClickNavItem}
            >
              <div className="d-flex align-items-center">
                {item.icon && (
                <img
                  className="nav-icon"
                  src={`/images/nav-icon/${item.icon}`}
                  alt={item.name}
                />
                )}
                {item.name}
              </div>
              <div style={{ visibility: item.children ? 'visible' : 'hidden' }}>
                <img
                  className={`nav-dd-icon ${isExpanded ? 'active' : ''}`}
                  src="/images/common/arrow_drop_down.svg"
                  alt="arrow_drop_down"
                />
              </div>
            </div>
          ) : (
            <Link
              to={
            item.children && item.children.length > 0
              ? item.children[0].url
              : item.url
          }
              className={`nav-left ${isActiveParent ? 'active' : ''}`}
              role="presentation"
              onClick={handleClickNavItem}
            >
              <div className="d-flex align-items-center">
                {item.icon && (
                <img
                  className="nav-icon"
                  src={`/images/nav-icon/${item.icon}`}
                  alt={item.name}
                />
                )}
                {item.name}
              </div>
              <div style={{ visibility: item.children ? 'visible' : 'hidden' }}>
                <img
                  className={`nav-dd-icon ${isExpanded ? 'active' : ''}`}
                  src="/images/common/arrow_drop_down.svg"
                  alt="arrow_drop_down"
                />
              </div>
            </Link>
          )}

          {item.children && item.children.length > 0 && isExpanded && (
          <div className="nav-list">
            {item.children.map(
              (x) => {
                if (isPermission(item.url, x.url) === true) {
                  return (
                    <div key={x.url} className="nav-item">
                      <Link
                        onClick={handleClickNavChildItem}
                        to={x.url}
                        className={`nav-left-child ${
                          window.location.pathname.includes(x.url) ? 'active' : ''
                        }`}
                      >
                        <div className="d-flex align-items-center">{x.name}</div>
                      </Link>
                    </div>
                  )
                }
              },
            )}
          </div>
          )}
        </div>
      )
  )
}

export default NavItem
