export const url = {
  // quản lý tài khoản
  account_management: '/quan-ly-tai-khoan',
  internal_account: '/quan-ly-tai-khoan/tai-khoan-noi-bo',
  // external_account: '/quan-ly-tai-khoan/tai-khoan-ngoai',
  doanhnghiep_account: '/quan-ly-tai-khoan/tai-khoan-doanh-nghiep',
  ctv_account: '/quan-ly-tai-khoan/tai-khoan-ctv',

  partner_account: '/quan-ly-tai-khoan/tai-khoan-doi-tac',
  // sim gói
  sim_package: '/sim-goi',
  upload_sim_package: '/sim-goi/upload',
  approve_list_upload_sim_goi: '/sim-goi/phe-duyet',
  active_sim_package: '/sim-goi/kich-hoat-sim',
  sim_package_management: '/sim-goi/kho-sim',
  extend_sim_package: '/sim-goi/gia-han-sim',
  // sim số
  sim_number: '/sim-so',
  upload_sim_number: '/sim-so/upload',
  approve_list_upload_sim_number: '/sim-so/phe-duyet',
  sim_number_management: '/sim-so/kho-sim',
  active_sim_number: '/sim-so/kich-hoat-sim',
  extend_sim_number: '/sim-so/gia-han-sim',
  // du lịch
  sim_travel: '/sim-du-lich',
  upload_sim_travel: '/sim-du-lich/upload',
  approve_list_upload_sim_travel: '/sim-du-lich/phe-duyet',
  sim_travel_management: '/sim-du-lich/kho-sim',
  active_sim_travel: '/sim-du-lich/kich-hoat-sim',
  // sim M2M
  sim_m2m: '/sim-m2m',
  upload_sim_m2m: '/sim-m2m/upload',
  approve_list_upload_sim_m2m: '/sim-m2m/phe-duyet',
  sim_m2m_management: '/sim-m2m/kho-sim',
  active_sim_m2m: '/sim-m2m/kich-hoat-sim',
  extend_sim_m2m: '/sim-m2m/gia-han-sim',
  // package manage
  package_manage: '/quan-ly-goi-cuoc',
  package_manage_sim_dl: '/quan-ly-goi-cuoc/gc-sim-dl',
  package_manage_sim_m2m: '/quan-ly-goi-cuoc/gc-sim-m2m',
  package_manage_sim_goi: '/quan-ly-goi-cuoc/gc-sim-goi',
  package_manage_sim_so: '/quan-ly-goi-cuoc/gc-sim-so',

  // Website
  website_manage: '/quan-ly-website',
  website_customer: '/quan-ly-website/trang-khach-hang/',
  website_dl: '/quan-ly-website/dl',
  website_m2m: '/quan-ly-website/m2m',
  website_china: '/quan-ly-website/china',
  website_taiwan: '/quan-ly-website/taiwan',

  // Flash sale
  flash_sale: '/flash-sale',
  flash_sale_sim: '/flash-sale/sim',
  flash_sale_phu_kien: '/flash-sale/phu-kien',

  // Đơn hàng
  order_management: '/quan-ly-don-hang',
  sim_order_management: '/quan-ly-don-hang/quan-ly-sim-so',
  topup_order_management: '/quan-ly-don-hang/nap-the',
  provider_order_management: '/quan-ly-don-hang/nha-mang',

  // Quản lý Ticket
  ticket_management: '/quan-ly-ticket',

  // Quản lý sản phẩm
  product_management: '/quan-ly-san-pham',
  product_portfolio: '/quan-ly-san-pham/danh-muc',
  product: '/quan-ly-san-pham/san-pham',
  product_inventory: '/quan-ly-san-pham/quan-ly-kho',
  product_warranty_management: '/quan-ly-san-pham/kho-bao-hanh',

  // thiết lập chung
  setting_management: '/thiet-lap-chung',
  discount_card_management: '/thiet-lap-chung/nap-the',

  // quản lý điểm thưởng
  manage_bonus_point: '/quan-ly-diem-thuong',

  // quản lý bài viết
  manage_blog: '/quan-ly-bai-viet',

  // đối soát
  cross_check: '/doi-soat',

  // thống kê kinh doanh
  statistic: '/thong-ke-kinh-doanh',
}

// eslint-disable-next-line import/no-anonymous-default-export
const navs = [
  // // {
  // //   name: 'Cài đặt bộ lọc',
  // //   url: '/cai-dat-bo-loc',
  // //   icon: 'caidatboloc.svg',
  // // },
  {
    name: '',
    group: [
      {
        name: 'Thống kê kinh doanh',
        url: url.statistic,
        icon: 'thongkekinhdoanh.svg',
      },
      // {
      //   name: 'Thông báo',
      //   url: '/thong-bao',
      //   icon: 'thongbao.svg',
      // },
      {
        name: 'Quản lý điểm thưởng',
        url: '/quan-ly-diem-thuong',
        icon: 'quanlydiemthuong.svg',
      },
      {
        name: 'Đối soát',
        url: url.cross_check,
        icon: 'doisoat.svg',
      },
    ],
  },
  {
    name: 'Nền tảng',
    group: [
      {
        name: 'Website',
        url: url.website_manage,
        icon: 'catalog.svg',
        children: [
          {
            name: 'Trang khách hàng',
            url: url.website_customer,
          },
          {
            name: 'Landing page sim DL',
            url: url.website_dl,
          },
          {
            name: 'Landing page sim M2M',
            url: url.website_m2m,
          },
          {
            name: 'Landing page sim China',
            url: url.website_china,
          },
          {
            name: 'Landing page sim Đài Loan',
            url: url.website_taiwan,
          },
        ],
      },
      {
        name: 'Flash sale',
        url: url.flash_sale,
        icon: 'flashsale.svg',
      },
      {
        name: 'Quản lý bài viết',
        url: url.manage_blog,
        icon: 'quanlybaiviet.svg',
      },
      {
        name: 'Đơn hàng',
        url: url.order_management,
        icon: 'quanlydonhang.svg',
        children: [
          {
            name: 'Sim số Simplus',
            url: url.sim_order_management,
          },
          {
            name: 'Sim số nhà mạng',
            url: url.provider_order_management,
          },
          {
            name: 'Thẻ cào',
            url: url.topup_order_management,
          },
        ],
      },
      {
        name: 'Quản lý Ticket',
        url: url.ticket_management,
        icon: 'quanlyticket.svg',
      },
    ],
  },
  {
    name: 'Sản phẩm',
    group: [
      {
        name: 'Sim Du lịch',
        url: url.sim_travel,
        icon: 'sim.svg',
        children: [
          {
            name: 'Upload danh sách sim',
            url: url.upload_sim_travel,
          },
          {
            name: 'Phê duyệt danh sách upload',
            url: url.approve_list_upload_sim_travel,
          },
          {
            name: ' Quản lý kho sim',
            url: url.sim_travel_management,
          },
          {
            name: 'Quản lý kích hoạt sim',
            url: url.active_sim_travel,
          },
        ],
      },
      {
        name: 'Sim M2M',
        url: url.sim_m2m,
        icon: 'sim.svg',
        children: [
          {
            name: 'Upload danh sách sim',
            url: url.upload_sim_m2m,
          },
          {
            name: 'Phê duyệt danh sách upload',
            url: url.approve_list_upload_sim_m2m,
          },
          {
            name: ' Quản lý kho sim',
            url: url.sim_m2m_management,
          },
          {
            name: 'Quản lý kích hoạt sim',
            url: url.active_sim_m2m,
          },
          {
            name: 'Gia hạn gói cước',
            url: url.extend_sim_m2m,
          },
        ],
      },
      {
        name: 'Sim Gói',
        url: url.sim_package,
        icon: 'sim.svg',
        children: [
          {
            name: 'Upload danh sách sim',
            url: url.upload_sim_package,
          },
          {
            name: 'Phê duyệt danh sách upload',
            url: url.approve_list_upload_sim_goi,
          },
          {
            name: 'Quản lý kho sim',
            url: url.sim_package_management,
          },
          {
            name: 'Quản lý kích hoạt sim',
            url: url.active_sim_package,
          },
          {
            name: 'Gia hạn gói cước',
            url: url.extend_sim_package,
          },
        ],
      },
      {
        name: 'Sim Số',
        url: url.sim_number,
        icon: 'sim.svg',
        children: [
          {
            name: 'Upload danh sách sim',
            url: url.upload_sim_number,
          },
          {
            name: 'Phê duyệt danh sách upload',
            url: url.approve_list_upload_sim_number,
          },
          {
            name: ' Quản lý kho sim',
            url: url.sim_number_management,
          },
          {
            name: 'Quản lý kích hoạt sim',
            url: url.active_sim_number,
          },
          {
            name: 'Gia hạn gói cước',
            url: url.extend_sim_number,
          },
        ],
      },
      {
        name: 'Gói cước',
        url: url.package_manage,
        icon: 'quanlygoicuoc.svg',
        children: [
          {
            name: 'Sim Du Lịch',
            url: url.package_manage_sim_dl,
          },
          {
            name: 'Sim M2M',
            url: url.package_manage_sim_m2m,
          },
          {
            name: 'Sim Gói + Sim Số',
            url: url.package_manage_sim_goi,
          },
          // {
          //   name: 'Sim Số',
          //   url: url.package_manage_sim_so,
          // },
        ],
      },
      {
        name: 'Quản lý sản phẩm',
        url: url.product_management,
        icon: 'quanlysanpham.svg',
        children: [
          {
            name: 'Danh mục sản phẩm',
            url: url.product_portfolio,
          },
          {
            name: 'Danh sách sản phẩm',
            url: url.product,
          },
          {
            name: 'QL xuất/ nhập kho',
            url: url.product_inventory,
          },
          {
            name: 'QL bảo hành',
            url: url.product_warranty_management,
          },
        ],
      },
    ],
  },
  {
    name: 'Cài đặt',
    group: [
      {
        name: 'Quản lý tài khoản',
        url: url.account_management,
        icon: 'quanlytaikhoan.svg',
        children: [
          {
            name: 'Tài khoản nội bộ',
            url: url.internal_account,
          },
          // {
          //   name: 'Tài khoản ngoài',
          //   url: url.external_account,
          // },
          {
            name: 'Tài khoản doanh nghiệp',
            url: url.doanhnghiep_account,
          },
          {
            name: 'Tài khoản CTV',
            url: url.ctv_account,
          },
          {
            name: 'Tài khoản đối tác',
            url: url.partner_account,
          },
        ],
      },
      {
        name: 'Thiết lập chung',
        url: url.setting_management,
        icon: 'thiet-lap-chung-v2.svg',
        children: [
          {
            name: 'Nạp thẻ',
            url: url.discount_card_management,
          },
        ],
      },
    ],
  },
]

export default navs
